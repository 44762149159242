<script setup lang="ts">
import { IonPage } from '@ionic/vue'
import { useRouter, useRoute } from 'vue-router'
import { computed, onBeforeMount, ref } from 'vue'
import { SERVING_SIZE_OPTIONS, ServingSizeOption } from '@lyka-pet-food/lyka-shared-types/lib/types/ProductService'
import { useRecommendation } from '@/composables/useRecommendation'
import { FeedingPlan, FeedingPlanType } from '@repo/api-types/src/generated/recommendation'
import { useAnimalsStore } from '@/stores/animals'
import { loadingWrapper } from '@/utils/function'
import EditServingSize from '@/views/EditServingSize.vue'
import EditServingSizePlanConfirm from '@/views/EditServingSizePlanConfirm.vue'
import EditServingSizePlanSubmission from '@/views/EditServingSizePlanSubmission.vue'
import { useSubscriptionStore } from '@/stores/subscriptions'
import { useOrderStore } from '@/stores/orders'
import { FeedingPlanServingSize } from '@lyka-pet-food/contracts/services/subscription'
import { useSubscriptions } from '@/composables/useSubscriptions'

// f: full -> f125 -> 125
// h: no longer used
// d: double -> d0800 -> 400
// q: quadruple -> q1600 -> 400
// t: triple -> t1800 -> 600
const servingSizeMappingOptions: Record<string, string> = {
  f0125: '0125F',
  h0125: '0062.5H',
  h150: '0075H',
  h200: '0100H',
  f0150: '0150F',
  f0200: '0200F',
  f0250: '0250F',
  f0300: '0300F',
  f0400: '0400F',
  f0500: '0500F',
  f0600: '0600F',
  f0700: '0700F',
  d0800: '0800D',
  d1000: '1000D',
  d1200: '1200D',
  d1400: '1400D',
  q1600: '1600Q',
  t1800: '1800T',
}

const servingSizeMappingOptionsReverse: Record<string, string> = {
  '0125F': 'f0125',
  '0062.5H': 'h0125',
  '0075H': 'h150',
  '0100H': 'h200',
  '0150F': 'f0150',
  '0200F': 'f0200',
  '0250F': 'f0250',
  '0300F': 'f0300',
  '0400F': 'f0400',
  '0500F': 'f0500',
  '0600F': 'f0600',
  '0700F': 'f0700',
  '0800D': 'd0800',
  '1000D': 'd1000',
  '1200D': 'd1200',
  '1400D': 'd1400',
  '1600Q': 'q1600',
  '1800T': 't1800',
}

const router = useRouter()
const route = useRoute()

const dogId = route.params.dogId
const feedingPlanId = route.params.feedingPlanId

const subscriptionStore = useSubscriptionStore()
const orderStore = useOrderStore()
const animalsStore = useAnimalsStore()

const nextDeliveryDate = computed(() => {
  const date = orderStore.futureOrders[0]?.deliveryDate
  if (!date) {
    return
  }
  return new Date(date)
})

const currentSubscription = computed(() => subscriptionStore.userActiveSubscription)

const feedingPlan = computed(() => currentSubscription.value?.feedingPlans?.find((x) => x.id === feedingPlanId))

const currentDog = computed(() => animalsStore.getDogById(dogId as string))

const servingSizeInGrams = computed(() => {
  const match = feedingPlan.value?.currentDailyServingSizeInGrams.match(/[1-9]\d*/)
  if (match) {
    return match[0]
  }
})

const recommendedServingSizeInGrams = computed(() => {
  const match = feedingPlan.value?.recommendedDailyServingSizeInGrams.match(/[1-9]\d*/)
  if (match) {
    return match[0]
  }
})

const currentServingSizeId = computed(() => {
  let multiplier = 1
  const feedingPlanType = feedingPlan.value?.type
  if (!servingSizeInGrams.value || !feedingPlanType) {
    return
  }
  if (feedingPlanType === 'half_bowl') {
    multiplier = 2
  }

  for (let item in FeedingPlanServingSize) {
    const match = item.match(/[1-9]\d*/)

    if (match) {
      if (parseFloat(servingSizeInGrams.value) * multiplier === parseFloat(match[0])) {
        return servingSizeMappingOptions[item]
      }
    }
  }
})

const currentRecommendationPlan = ref<FeedingPlan | undefined>(undefined)
const selectedRecommendationPlan = ref<FeedingPlan | undefined>(undefined)

const loading = ref<boolean>(false)
const setLoading = (enable: boolean) => (loading.value = enable)

const error = ref<Error | undefined>(undefined)
const setError = (err: Error) => (error.value = err)
const resetError = () => (error.value = undefined)

const selectedServingSize = ref<FeedingPlanServingSize | undefined>(undefined)
const setSelectedServingSize = (servingSize: string) =>
  (selectedServingSize.value = servingSizeMappingOptionsReverse[servingSize] as FeedingPlanServingSize)

const recommendedServingSize = computed(() => {
  let multiplier = 1
  const feedingPlanType = feedingPlan.value?.type

  if (!recommendedServingSizeInGrams.value || !feedingPlanType) {
    return
  }
  if (feedingPlanType === 'half_bowl') {
    multiplier = 2
  }

  for (let item in FeedingPlanServingSize) {
    const match = item.match(/[1-9]\d*/)

    if (match) {
      if (parseFloat(recommendedServingSizeInGrams.value) * multiplier === parseFloat(match[0])) {
        return servingSizeMappingOptions[item]
      }
    }
  }
})

const getRecommendationPlan = async (servingSize: ServingSizeOption) => {
  const animalId = dogId
  if (!animalId) {
    setError(new Error('Could not get animal id'))
    return
  }
  const recommendationResult = await useRecommendation().getRecommendation(animalId as string, servingSize)
  const planTemplateId = feedingPlan.value?.type
  const plan = recommendationResult.recommendation?.feedingPlansRecommendation?.find((r: FeedingPlan) =>
    planTemplateId?.startsWith(r.feedingPlanType),
  )
  if (!plan) {
    setError(new Error('Could not get recommendation plan'))
    return
  }
  return plan
}

const setCurrentRecommendationPlan = () =>
  loadingWrapper(async () => {
    if (!currentServingSizeId.value) {
      return
    }
    currentRecommendationPlan.value = await getRecommendationPlan(currentServingSizeId.value as any)
  }, setLoading)

const setSelectedRecommendationAndMoveForward = (servingSize: ServingSizeOption) =>
  loadingWrapper(async () => {
    setSelectedServingSize(servingSize)
    selectedRecommendationPlan.value = await getRecommendationPlan(servingSize)
    setForwardStep()
  }, setLoading)

const currentStep = ref<number>(1)
const resetStep = () => (currentStep.value = 1)
const setBackStep = () => (currentStep.value = Math.max(1, currentStep.value - 1))
const setForwardStep = () => (currentStep.value = Math.min(3, currentStep.value + 1))

const submitFeedingPlan = () =>
  loadingWrapper(async () => {
    if (currentSubscription.value && feedingPlan.value && currentDog.value && selectedServingSize.value) {
      await useSubscriptions().updateSubscriptionFeedingPlan(
        selectedServingSize.value,
        currentSubscription.value.id,
        feedingPlan.value.id,
      )
      await subscriptionStore.load()
      // currentRecommendationPlan.value = await getRecommendationPlan(selectedServingSize.value)
      setForwardStep()
    }
  }, setLoading)

const backToDogProfiles = () => {
  resetStep()
  router.push({
    name: 'dogs',
  })
}

onBeforeMount(() => {
  loadingWrapper(async () => {
    await Promise.all([animalsStore.load(), subscriptionStore.load(), orderStore.load()])
    await setCurrentRecommendationPlan()
  }, setLoading)
})
</script>

<template>
  <ion-page>
    <EditServingSize
      v-if="currentStep === 1 && currentServingSizeId"
      :dog-name="currentDog?.name"
      :initial-serving-size="currentServingSizeId"
      :recommended-serving-size="recommendedServingSize"
      :loading="loading"
      @cancel="backToDogProfiles"
      @review-changes="setSelectedRecommendationAndMoveForward"
    />
    <EditServingSizePlanConfirm
      v-if="currentStep === 2 && currentRecommendationPlan && selectedRecommendationPlan"
      :loading="loading"
      :dog-name="currentDog?.name"
      :current-recommendation-plan="currentRecommendationPlan"
      :selected-recommendation-plan="selectedRecommendationPlan"
      :next-delivery-date="nextDeliveryDate"
      @cancel="setBackStep"
      @submit-plan="submitFeedingPlan"
    />
    <EditServingSizePlanSubmission
      v-if="currentStep === 3"
      :dog-name="currentDog?.name"
      :next-delivery-date="nextDeliveryDate"
      @dismiss="backToDogProfiles"
    />
  </ion-page>
</template>
