import { useClient } from '@/composables/useClient'
import {
  RetrieveDogReturnDto,
  DogBreedReturnDto,
  DogHealthIssueReturnDto,
  DogFoodTypeReturnDto,
  DogFoodAllergenReturnDto,
  UpdateAnimalDto,
} from '@repo/api-types/src/generated/entities'

export const useDogs = () => {
  const getDogs = async (): Promise<{
    error?: string | null
    returnedDogs?: RetrieveDogReturnDto[]
  }> => {
    const client = await useClient()
    const { status, body } = await client.entities.getDogs()

    let error = null
    if (status == 500) {
      error = body.error
      return { error }
    } else if (status == 200) {
      return { returnedDogs: body }
    }

    return { error: 'Unspecified server error' }
  }

  const getDogsData = async (): Promise<{
    error?: string | null
    data?: {
      breeds: DogBreedReturnDto[]
      healthIssues: DogHealthIssueReturnDto[]
      foodTypes: DogFoodTypeReturnDto[]
      foodAllergens: DogFoodAllergenReturnDto[]
    }
  }> => {
    const client = await useClient()
    const { status, body } = await client.entities.getDogsData()

    let error = null
    if (status == 500) {
      error = body.error
      return { error }
    } else if (status == 200) {
      return { data: body }
    }

    return { error: 'Unspecified server error' }
  }

  const updateDog = async (identifier: string, dog: UpdateAnimalDto) => {
    const client = await useClient()
    const { status, body } = await client.entities.updateDog({
      body: {
        dog,
      },
      params: {
        id: identifier,
      },
    })

    let error = null
    if (status == 500) {
      error = body.error
      return { error }
    } else if (status == 200) {
      return { success: true }
    }

    return { error: 'Unspecified server error' }
  }

  return {
    updateDog,
    getDogs,
    getDogsData,
  }
}
