<script setup lang="ts">
import { PawQuantitySelector } from '@lyka-pet-food/lyka-common'
import { defineProps, ref, defineEmits, computed } from 'vue'
import { OrderLineItem } from '@repo/api-types/src/order/types'
import { useProductStore } from '@/stores/products'
import { useSubscriptionStore } from '@/stores/subscriptions'

const props = withDefaults(
  defineProps<{
    lineItem: OrderLineItem
  }>(),
  {},
)

const mealsQuantity = ref(props.lineItem.quantity)
const productStore = useProductStore()
const subscriptionStore = useSubscriptionStore()

const product = productStore.getProductByProductVariantId(props.lineItem.productId ?? '')
const productVariant = productStore.getProductVariantById(props.lineItem.productId ?? '')

const productSubscription = computed(() => {
  return subscriptionStore.userActiveSubscription?.extras?.find((x) => x.productVariantId === productVariant?.id)
})

const productSubscriptionFrequency = computed(() => {
  return productSubscription.value ? productSubscription.value.nthOrder : ''
})

const productWeight = computed(() => {
  return productVariant?.dimension?.weightGrams
})

const productImg = computed(() => {
  return product?.images.filter((x) => x.imageType === 'treat')[0]?.imageUrl
})

const emits = defineEmits<{
  (e: 'updateQuantity', quantity: number): void
  (e: 'viewMealsDetail'): void
}>()

const onClickAddMeals = (): void => {
  mealsQuantity.value++
  emits('updateQuantity', mealsQuantity.value)
}

const onClickRemoveMeals = (): void => {
  if (mealsQuantity.value > 0) {
    mealsQuantity.value--
    emits('updateQuantity', mealsQuantity.value)
  }
}

const onClickViewMealsDetail = (): void => {
  emits('viewMealsDetail')
}

const onUpdateQuantity = (quantity: number | undefined): void => {
  if (!quantity) return

  mealsQuantity.value = Number(quantity)
  emits('updateQuantity', mealsQuantity.value)
}
</script>

<template>
  <div class="tw-flex tw-w-full tw-space-x-5 md:tw-flex-col md:tw-space-x-0 md:tw-space-y-4">
    <div
      class="tw-flex tw-h-[115px] tw-w-1/3 tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-xl tw-bg-surface-brand-secondary max-[360px]:tw-h-[85px] md:tw-h-[164px] md:tw-w-[229px]"
      @click="onClickViewMealsDetail"
    >
      <div class="tw-relative tw-h-auto tw-w-4/5">
        <img :src="productImg" class="tw-h-auto tw-w-full tw-scale-[1.3] md:tw-scale-100" />
      </div>
    </div>

    <div class="tw-flex tw-w-2/3 tw-flex-col tw-space-y-4 md:tw-w-full">
      <div class="tw-space-y-0.5">
        <div class="tw-text-body-lg">{{ product?.name ?? '' }}</div>
        <div>{{ productWeight }}</div>
        <div v-if="productSubscription" class="tw-flex">
          <img src="@/assets/icons/repeat-brand-green.svg" class="tw-mr-2" />every
          {{ productSubscriptionFrequency === 1 ? 'order' : productSubscriptionFrequency + ' orders' }}
        </div>
      </div>

      <div class="tw-w-[165px] tw-pt-2 md:tw-pt-0">
        <PawQuantitySelector
          data-testid="quantity-selector"
          v-model="mealsQuantity"
          @click-plus="onClickAddMeals"
          @click-minus="onClickRemoveMeals"
          @update:model-value="onUpdateQuantity"
          :disable-minus="mealsQuantity === 0"
          :disable-plus="mealsQuantity >= 100"
        />
      </div>
    </div>
  </div>
</template>
