<script setup lang="ts">
import ViewLayout from '@/components/ViewLayout.vue'
import { RetrieveDogReturnDto, UpdateAnimalDto } from '@repo/api-types/src/generated/entities'
import { PawHeading, PawTextField, PawDivider, PawButtonOutlined, PawSelect } from '@lyka-pet-food/lyka-common'
import { onBeforeMount, ref, computed } from 'vue'
import { birthdayToYearMonth } from '@/utils/birthdayToAge'
import StickyBottomGeneral from '@/components/stickyNav/StickyBottomGeneral.vue'
import { useAnimalsStore } from '@/stores/animals'
import { ageToDateOfBirth } from '@/utils/ageToDateOfBirth'

const props = defineProps<{
  dog: RetrieveDogReturnDto
}>()

const animalsStore = useAnimalsStore()

const petName = ref()
const petGender = ref()
// FIXME: Revert this when we have a PawMultiSelect
const petBreed = ref()
const selectedAge = ref(true)
const ageYear = ref()
const ageMonth = ref()
const dateOfBirth = ref('')

const emits = defineEmits<{
  (e: 'cancelEditPet'): void
  (e: 'confirmEditPet', identifier: string, dog: UpdateAnimalDto, data: Partial<UpdateAnimalDto>): void
}>()

const onClickToggleAgeSelection = (): void => {
  selectedAge.value = !selectedAge.value
}

const onClickSelectGender = (gender: string): void => {
  petGender.value = gender
}

const onConfirmEditPet = () => {
  emits('confirmEditPet', props.dog.identifier, props.dog, {
    name: petName.value,
    gender: petGender.value,
    breeds: [petBreed.value],
    // FIXME: ageYear and ageMonth missing from type
    dateOfBirth: dateOfBirth.value ? ageToDateOfBirth(ageYear.value, ageMonth.value) : dateOfBirth.value,
  })
}

const formError = computed(() => {
  if (!petName.value) return 'Name is required'
  if (!petGender.value) return 'Gender is required'
  if (!petBreed.value) return 'Breed is required'
  return ''
})

onBeforeMount(() => {
  if (props.dog) {
    petName.value = props.dog.name
    petGender.value = props.dog.gender
    // FIXME: Revert this when we have a PawMultiSelect
    petBreed.value = props.dog.breeds ? props.dog.breeds[0] : null
    const age = birthdayToYearMonth(props.dog.dateOfBirth)
    ageYear.value = age.year
    ageMonth.value = age.month
    dateOfBirth.value = props.dog.dateOfBirth ?? ''
  }
})
</script>

<template>
  <ViewLayout>
    <div class="tw-w-full tw-max-w-[1140px] tw-space-y-8 tw-pb-48">
      <div class="tw-flex tw-w-full tw-flex-row tw-items-start tw-self-start">
        <img
          src="@/assets/icons/chevron-left.svg"
          class="tw-cursor-pointer tw-pr-4 md:tw-pt-1"
          @click="emits('cancelEditPet')"
        />
        <div class="tw-space-y-1">
          <PawHeading size="3xl">Edit {{ dog?.name }}'s details</PawHeading>
        </div>
      </div>

      <div class="tw-w-full tw-space-y-8">
        <PawTextField :label="`${dog?.name}'s name`" v-model="petName" class="lg:tw-w-80" />

        <PawDivider />

        <div>{{ dog?.name }}'s sex</div>
        <div class="tw-flex tw-flex-col tw-space-y-4 lg:tw-w-[670px] lg:tw-flex-row lg:tw-space-x-4 lg:tw-space-y-0">
          <PawButtonOutlined
            class="tw-w-full tw-border-solid"
            :class="[petGender === 'male' && 'tw-bg-surface-brand-secondary']"
            @click="onClickSelectGender('male')"
            >Male</PawButtonOutlined
          >
          <PawButtonOutlined
            class="tw-w-full tw-border-solid"
            :class="[petGender === 'female' && 'tw-bg-surface-brand-secondary']"
            @click="onClickSelectGender('female')"
            >Female</PawButtonOutlined
          >
        </div>

        <PawDivider />

        <PawSelect
          :options="animalsStore.breedsToSelectOptions()"
          name="breed"
          :label="`${dog?.name}'s breed`"
          v-model="petBreed"
          class="lg:tw-w-80"
        />

        <PawDivider />

        <div>{{ dog?.name }}'s age</div>

        <div
          class="tw-flex tw-h-16 tw-items-center tw-justify-center tw-rounded-full tw-bg-[#F3F0E9] tw-px-3 lg:tw-w-80"
        >
          <div
            class="tw-rounded-button-segment"
            :class="[selectedAge === false && ['tw-bg-surface-brand', 'tw-text-content-invert']]"
            @click="onClickToggleAgeSelection"
          >
            Date of birth
          </div>
          <div
            class="tw-rounded-button-segment"
            :class="[selectedAge && ['tw-bg-surface-brand', 'tw-text-content-invert']]"
            @click="onClickToggleAgeSelection"
          >
            Approximate age
          </div>
        </div>

        <div class="tw-space-y-4 lg:tw-w-80">
          <PawTextField
            v-if="selectedAge"
            :placeholder="`${ageYear}`"
            suffix="Years"
            v-model="ageYear"
            type="number"
            class="!tw-z-0"
          />
          <PawTextField v-if="selectedAge" suffix="Months" v-model="ageMonth" type="number" class="!tw-z-0" />
        </div>

        <PawTextField v-if="!selectedAge" v-model="dateOfBirth" type="date" class="tw-input-block lg:tw-w-80" />

        <div class="tw-text-content-danger">{{ formError }}</div>
      </div>
    </div>
    <template #postView>
      <StickyBottomGeneral
        :confirm-disabled="formError !== ''"
        @cancel="emits('cancelEditPet')"
        @confirm="onConfirmEditPet"
      />
    </template>
  </ViewLayout>
</template>

<style scoped>
.tw-rounded-button-segment {
  @apply tw-flex tw-h-[40px] tw-w-1/2 tw-items-center tw-justify-center tw-rounded-full;
}
.tw-input-block >>> input {
  @apply tw-block;
}
</style>
