<script setup lang="ts">
import ViewLayout from '@/components/ViewLayout.vue'
import { PawButtonFilled, PawHeading } from '@lyka-pet-food/lyka-common'
import { RetrieveDogReturnDto } from '@repo/api-types/src/generated/entities'
import { computed } from 'vue'

const props = defineProps<{
  dog?: RetrieveDogReturnDto
}>()

const emits = defineEmits<{
  (e: 'confirm'): void
}>()
</script>
<template>
  <ViewLayout>
    <div class="tw-flex tw-w-full tw-max-w-[1140px] tw-justify-center tw-space-y-8 tw-pb-48 tw-pt-12">
      <div class="tw-flex tw-max-w-[400px] tw-flex-col tw-items-center tw-justify-center tw-space-y-12">
        <img src="@/assets/icons/success-tick.png" class="tw-h-auto tw-w-20" />
        <PawHeading size="2xl">Your current plan is just right</PawHeading>

        <div class="tw-w-full">
          <div
            class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-space-y-4 tw-rounded-xl tw-bg-white tw-p-4 tw-py-6"
          >
            <div class="tw-rounded-lg tw-bg-surface-brand-secondary tw-p-1 tw-px-2">Current</div>
            <img class="tw-max-w-[250px]" src="@/assets/images/meals/bowl-general.png" alt="bowl-general" />
            <div class="tw-text-center">
              <!-- <div class="tw-text-xl tw-font-bold">{{ subscriptionForPet?.dailyServingSizeInGrams }}g per day</div> -->
              <div>Feeding 100% Lyka</div>
            </div>
          </div>
        </div>

        <div><span class="tw-font-bold">Still unsure?</span> Contact our support team</div>

        <div class="tw-w-full">
          <PawButtonFilled class="tw-w-full" @click="emits('confirm')">Okay</PawButtonFilled>
        </div>
      </div>
    </div>
  </ViewLayout>
</template>
