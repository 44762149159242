<script setup lang="ts">
import { PawDivider, PawButtonOutlined, PawButtonFilled } from '@lyka-pet-food/lyka-common'
import StickyBottomAddProductMessage from '@/components/stickyNav/StickyBottomAddProductMessage.vue'
import { computed } from 'vue'
import { EditProductItem } from '@/types/product'
import { BillingCycleOrder } from '@repo/api-types'
import { EnrichedOrder } from '@repo/api-types/src/order/types'

const props = defineProps<{
  items: EditProductItem[]
  order?: EnrichedOrder
}>()

const emits = defineEmits<{
  (e: 'cancel'): void
  (e: 'confirm'): void
  (e: 'updateFrequency', value: number): void
}>()

const onUpdateFrequency = (value: number) => {
  emits('updateFrequency', value)
}
</script>

<template>
  <div
    class="tw-w-full tw-bg-surface-light tw-p-4 tw-rounded-t-3xl md:tw-rounded-none tw-space-y-4 md:tw-space-y-0 tw-pb-4 tw-fixed tw-bottom-0 tw-shadow-[2px_0px_10px_3px_rgba(0,0,0,0.05)] md:tw-flex md:tw-justify-center"
  >
    <StickyBottomAddProductMessage
      class="md:tw-hidden"
      :items="props.items"
      :order="props.order"
      @update-frequency="onUpdateFrequency"
    />

    <div class="tw-flex tw-justify-between tw-space-x-4 tw-max-w-[1076px] tw-w-full md:tw-items-center">
      <PawButtonOutlined class="tw-border-solid tw-w-1/2 md:tw-w-[264px]" @click="emits('cancel')" name="Cancel"
        >Cancel</PawButtonOutlined
      >
      <StickyBottomAddProductMessage
        class="tw-hidden md:tw-flex md:tw-flex-col md:tw-max-w-[326px]"
        :items="props.items"
        :order="props.order"
        @update-frequency="onUpdateFrequency"
      />
      <PawButtonFilled class="tw-w-1/2 md:tw-w-[264px]" @click="emits('confirm')" name="Confirm"
        >Confirm Extras</PawButtonFilled
      >
    </div>
  </div>
</template>
