<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { pluralize } from '@/utils/pluralize'
import { PawAccordion, PawDivider, PawRadio, PawRadioWrap } from '@lyka-pet-food/lyka-common'
import { EditProductItem } from '@/types/product'
import ChevronDownIcon from '@/assets/icons/chevron-down.svg'
import ChevronUpIcon from '@/assets/icons/chevron-up.svg'
import { BillingCycleOrder } from '@repo/api-types'
import { EnrichedOrder } from '@repo/api-types/src/order/types'

const props = defineProps<{
  items: EditProductItem[]
  order?: EnrichedOrder
}>()

const emits = defineEmits<{
  (e: 'updateFrequency', value: number): void
}>()

const subscriptionState = ref(false)
const showProducts = ref(false)

const subscriptionFrequencyIndex = ref(0)

const showFrequencies = ref(false)

const subscriptionFrequencies = computed(() => {
  return [1]
})

watch(subscriptionState, () => {
  if (subscriptionState.value) {
    emits('updateFrequency', subscriptionFrequencies.value[subscriptionFrequencyIndex.value])
  } else {
    emits('updateFrequency', 0)
  }
})

const toggleSubscription = () => {
  subscriptionState.value = !subscriptionState.value
}

const toggleFrequencies = () => {
  showFrequencies.value = !showFrequencies.value
}

const toggleProducts = () => {
  showProducts.value = !showProducts.value
}

const onSelectFrequency = (index: number) => {
  subscriptionFrequencyIndex.value = index
  showFrequencies.value = false
  subscriptionState.value = true
}

const totalProductItems = computed(() => {
  return props.items.reduce((a, b) => a + b.quantity, 0)
})

const totalProductPrice = computed(() => {
  return props.items.reduce((a, b) => a + (b.price ?? 0) * b.quantity, 0).toFixed(2)
})
</script>

<template>
  <div class="tw-w-full tw-space-y-4">
    <div class="tw-flex tw-w-full tw-cursor-pointer tw-justify-between" @click="toggleProducts">
      <div class="tw-flex">
        {{ totalProductItems }} item <img :src="showProducts ? ChevronUpIcon : ChevronDownIcon" class="tw-pl-2" />
      </div>
      <div class="tw-font-bold">Total: ${{ totalProductPrice }}</div>
    </div>

    <div v-if="showProducts" class="tw-item-pop-up tw-item-pop-up-shadow" @click="toggleProducts">
      <div class="tw-space-y-3" v-for="(item, idx) in props.items" :key="idx">
        <div class="tw-flex tw-w-full tw-justify-between">
          <div class="tw-flex">
            {{ item.title }} <img src="@/assets/icons/repeat-brand-green.svg" class="tw-pb-1 tw-pl-2" />
          </div>
          <div>Qty: {{ item.quantity }}</div>
        </div>
        <PawDivider />
      </div>

      <div class="tw-flex tw-justify-between">
        <div class="tw-flex">
          {{ totalProductItems }} item <img :src="showProducts ? ChevronUpIcon : ChevronDownIcon" class="tw-pl-2" />
        </div>
        <div class="tw-font-bold">Total: ${{ totalProductPrice }}</div>
      </div>
    </div>

    <PawDivider />

    <div v-if="showProducts" class="tw-space-y-4 md:tw-hidden" v-for="(item, idx) in props.items" :key="idx">
      <div class="tw-flex tw-w-full tw-justify-between">
        <div class="tw-flex">
          {{ item.title }} <img src="@/assets/icons/repeat-brand-green.svg" class="tw-pb-1 tw-pl-2" />
        </div>
        <div>Qty: {{ item.quantity }}</div>
      </div>
      <PawDivider />
    </div>

    <div class="tw-row tw-relative tw-flex tw-w-fit tw-space-x-2">
      <PawRadio v-model="subscriptionState" :input-value="true" @click="toggleSubscription">
        <span class="tw-cursor-pointer">Send this extras every </span>
      </PawRadio>

      <div
        v-if="showFrequencies"
        class="tw-item-pop-up-shadow tw-absolute -tw-right-12 tw-bottom-8 tw-z-20 tw-rounded-2xl tw-border tw-bg-surface-light tw-p-2"
      >
        <div
          v-for="(frequency, index) in subscriptionFrequencies"
          :key="index"
          class="tw-cursor-pointer tw-rounded-xl tw-p-3 hover:tw-bg-surface-contrast"
          @click="onSelectFrequency(index)"
        >
          {{ index > 0 ? `${index + 1} orders` : `order` }}
        </div>
      </div>

      <div
        class="tw-border-secondary tw-item-pop-up-shadow tw-relative tw-flex -tw-translate-y-0.5 tw-cursor-pointer tw-rounded-md tw-border tw-bg-surface-light tw-px-1.5"
        @click="toggleFrequencies"
      >
        {{ subscriptionFrequencyIndex > 0 ? `${subscriptionFrequencyIndex + 1} orders` : 'order'
        }}<img src="@/assets/icons/repeat-solid.svg" class="tw-ml-1.5" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.tw-item-pop-up-shadow {
  @apply tw-shadow-[2px_6px_20px_0_rgba(0,0,0,0.08)];
}
.tw-item-pop-up {
  @apply tw-absolute tw-bottom-[54.5px] tw-w-[359px] -tw-translate-x-4 tw-cursor-pointer tw-justify-between tw-border tw-bg-surface-primary tw-p-4;
  @apply tw-z-20 tw-hidden tw-flex-col tw-space-y-3 tw-rounded-2xl md:tw-flex;
}
</style>
