import { OrdersControllerGetOrderByIdResponse } from '../generated/order'
import { Subscription } from '../subscription/types'

export type OrderLineItem = OrdersControllerGetOrderByIdResponse['orderLineItems'][number]
export type Order = OrdersControllerGetOrderByIdResponse

export enum OrderDisplayType {
  EXCLUDED = 'EXCLUDED',
  PAST = 'PAST',
  CURRENT = 'CURRENT',
  FUTURE = 'FUTURE',
}

export type OrderFeedingPlan = {
  animalId: string
  planType: string
  skus: OrderLineItem[]
}

export type EnrichedOrder = Order & {
  displayType: OrderDisplayType
  feedingPlans: OrderFeedingPlan[]
  extras: OrderLineItem[]
}
