<script setup lang="ts">
import { ref, computed } from 'vue'
import { IonPage } from '@ionic/vue'
import { RetrieveDogReturnDto, UpdateAnimalDto } from '@repo/api-types/src/generated/entities'
import Dog from '@/views/Dog.vue'
import EditDogDetails from '@/views/EditDogDetails.vue'
import EditDogProfile from '@/views/EditDogProfile.vue'
import EditDogProfileReview from '@/views/EditDogProfileReview.vue'
import EditDogMealPlan from '@/views/EditDogMealPlan.vue'
import EditMeals, { EditProductMeal } from '@/views/EditMeals.vue'
import { useAnimalsStore } from '@/stores/animals'
import { useDogs } from '@/composables/useDogs'
import { useAppStateStore } from '@/stores/appState'
import { useRecommendation } from '@/composables/useRecommendation'
import { SERVING_SIZE_OPTIONS } from '@lyka-pet-food/lyka-shared-types/lib/types/ProductService'
import { FeedingPlan } from '@repo/api-types/src/generated/recommendation'
import { useProductStore } from '@/stores/products'
import { useSubscriptionStore } from '@/stores/subscriptions'
import { UpdateSubscriptionBodyFeedingPlanSku } from '@lyka-pet-food/contracts/services/subscription'
import { useSubscriptions } from '@/composables/useSubscriptions'
import { useOrderStore } from '@/stores/orders'
import { SubscriptionFeedingPlan } from '@repo/api-types/src/subscription/types'

const animalsStore = useAnimalsStore()
const appStateStore = useAppStateStore()
const productStore = useProductStore()
const subscriptionStore = useSubscriptionStore()
const orderStore = useOrderStore()

const currentDog = ref<RetrieveDogReturnDto>()
const feedingPlans = ref<FeedingPlan[]>()

const editDogMeals = ref<SubscriptionFeedingPlan>()

const toggleEditMeals = ref(false)
const toggleEditDogMealPlan = ref(false)
const toggleEditPetDetails = ref(false)
const toggleDogProfile = ref(false)
const toggleReviewFeedingPlan = ref(false)
const currentPetIndex = ref(0)

const productMeals = computed(() => {
  return productStore.allProducts?.filter((x) => x?.type === 'meal')
})

const onClickCancelEditPet = () => {
  toggleEditPetDetails.value = false
}

const onEditDogDetails = (dog: RetrieveDogReturnDto, petIndex: number) => {
  currentDog.value = dog
  currentPetIndex.value = petIndex
  toggleEditPetDetails.value = true
}

const onClickCancelEditDogProfile = () => {
  toggleDogProfile.value = false
}

const onEditDogProfile = (dog: RetrieveDogReturnDto) => {
  currentDog.value = dog
  toggleDogProfile.value = true
}

const onSaveDogProfile = async (identifier: string, dog: UpdateAnimalDto) => {
  const { success, error } = await useDogs().updateDog(identifier, dog)
  if (success) {
    await animalsStore.loadDogs()
    toggleDogProfile.value = false
    toggleReviewFeedingPlan.value = true
  }
}

const onSaveDogDetails = async (identifier: string, dog: UpdateAnimalDto, data: Partial<UpdateAnimalDto>) => {
  appStateStore.updateLoadingState(true)

  const { success, error } = await useDogs().updateDog(identifier, { ...dog, ...data })

  if (error) {
    console.error('Failed to update dog')
    return
  }

  await animalsStore.load()

  appStateStore.updateLoadingState(false)
  appStateStore.updateSuccessState(true)
  setTimeout(() => {
    appStateStore.updateSuccessState(false)
    toggleEditPetDetails.value = false
  }, 800)
}

const onReviewDogProfileConfirm = () => {
  toggleReviewFeedingPlan.value = false
}

const onEditDogMealPlan = async (dog: RetrieveDogReturnDto) => {
  currentDog.value = dog

  const currentFeedingPlan = subscriptionStore.getFeedingPlanByAnimalId(dog?.identifier)

  const currentServingSizeId = currentFeedingPlan?.currentDailyServingSizeInGrams

  const match = currentServingSizeId?.match(/[1-9]\d*/)

  if (!match) return

  const currentServingSize = match[0]

  const currentServingFullBowlAdjusted =
    currentFeedingPlan?.type === 'half_bowl' ? String(Number(currentServingSize) * 2) : currentServingSize

  const currentServingOption = SERVING_SIZE_OPTIONS.find(
    (servingSize) =>
      currentServingFullBowlAdjusted && parseInt(servingSize) === parseInt(currentServingFullBowlAdjusted),
  )

  if (!currentServingOption) {
    alert('No serving size set for this dog')
    return
  }

  appStateStore.updateLoadingState(true)

  const { recommendation } = await useRecommendation().getRecommendation(dog.identifier, currentServingOption)

  appStateStore.updateLoadingState(false)

  if (!recommendation || !recommendation.feedingPlansRecommendation) {
    alert('No plans found for this dog')
    return
  }

  feedingPlans.value = recommendation.feedingPlansRecommendation
  toggleEditDogMealPlan.value = true
}

const onUpdateMealPlanStatus = async (loading: boolean, successState: boolean) => {
  if (loading) {
    appStateStore.updateLoadingState(true)
  }

  if (successState) {
    await Promise.all([subscriptionStore.load(), orderStore.load()])
    appStateStore.updateLoadingState(false)
    appStateStore.updateSuccessState(true)
    // FIXME: Why a timeout?
    setTimeout(() => {
      appStateStore.updateSuccessState(false)
      toggleEditDogMealPlan.value = false
    }, 800)
  }
}

const onEditDogProfileMeals = (dog: RetrieveDogReturnDto) => {
  currentDog.value = dog
  editDogMeals.value = subscriptionStore.getFeedingPlanByAnimalId(dog?.identifier)
  toggleEditMeals.value = true
}

const onCancelEditMeals = () => {
  toggleEditMeals.value = false
}

const onConfirmEditMeals = async (updatedProducts: Array<EditProductMeal>) => {
  const subscriptionFeedingPlan = subscriptionStore.getFeedingPlanByAnimalId(currentDog.value?.identifier as string)

  if (!subscriptionFeedingPlan) {
    return
  }

  const dailyServingSizeMatch = subscriptionFeedingPlan.currentDailyServingSizeInGrams.match(/[1-9]\d*/)

  if (!dailyServingSizeMatch) {
    return
  }

  const dailyServingSize = dailyServingSizeMatch[0]

  let mealsToUpdate = [] as UpdateSubscriptionBodyFeedingPlanSku[]

  updatedProducts.forEach((product) => {
    // 1. Find product variant
    const productVariant = product.variants.find((x) => x.sku.includes(dailyServingSize))

    if (productVariant) {
      // 2. Push to mealsToUpdate
      mealsToUpdate.push({
        productVariantId: productVariant.id,
        ratio: product.quantity,
        price: productVariant.basePrice ?? 0,
      })
    }
  })

  appStateStore.updateLoadingState(true)

  const productEditResult = await useSubscriptions().updateSubscriptionMeals(
    subscriptionFeedingPlan.id ?? '',
    subscriptionFeedingPlan.id,
    mealsToUpdate,
  )
  if (productEditResult?.error || productEditResult?.error) {
    alert('Failed to update products')
    return
  }

  await orderStore.load()
  await subscriptionStore.load()

  appStateStore.updateLoadingState(false)

  appStateStore.updateSuccessState(true)
  setTimeout(() => {
    appStateStore.updateSuccessState(false)
    toggleEditMeals.value = false
  }, 1000)
}

const onClickCancelEditDogMealPlan = () => {
  toggleEditDogMealPlan.value = false
}
</script>

<template>
  <ion-page>
    <Dog
      v-if="
        !toggleEditPetDetails &&
        !toggleDogProfile &&
        !toggleReviewFeedingPlan &&
        !toggleEditDogMealPlan &&
        !toggleEditMeals
      "
      :pet-index="currentPetIndex"
      @edit-details="onEditDogDetails"
      @edit-profile="onEditDogProfile"
      @edit-meal-plan="onEditDogMealPlan"
      @edit-meals="onEditDogProfileMeals"
    />

    <EditDogDetails
      v-if="toggleEditPetDetails && currentDog"
      :dog="currentDog"
      @cancel-edit-pet="onClickCancelEditPet"
      @confirm-edit-pet="onSaveDogDetails"
    />

    <EditDogMealPlan
      v-if="toggleEditDogMealPlan && currentDog && feedingPlans"
      :dog="currentDog"
      :feeding-plans="feedingPlans"
      @cancel-edit-meal-plan="onClickCancelEditDogMealPlan"
      @update-meal-plan-status="onUpdateMealPlanStatus"
    />

    <EditDogProfile
      v-if="toggleDogProfile && currentDog"
      :dog="currentDog"
      @cancel-edit-profile="onClickCancelEditDogProfile"
      @save-edit-profile="onSaveDogProfile"
    />

    <EditMeals
      v-if="toggleEditMeals"
      :subscription-feeding-plan="editDogMeals"
      :product-meals="productMeals"
      :dog-name="currentDog?.name"
      :total-pouches="editDogMeals?.totalPouches"
      @confirm-edit-meal="onConfirmEditMeals"
      @cancel-edit-meal="onCancelEditMeals"
    />

    <EditDogProfileReview v-if="toggleReviewFeedingPlan" :dog="currentDog" @confirm="onReviewDogProfileConfirm" />
  </ion-page>
</template>
