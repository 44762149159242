import { initContract } from '@ts-rest/core'
import z from 'zod'
import { ErrorResponseSchema } from './general'
import { SERVING_SIZE_OPTIONS } from '@lyka-pet-food/lyka-shared-types/lib/types/ProductService'
import { FeedingPlansRecommendationOutput } from '@repo/api-types/src/generated/recommendation'

const c = initContract()

export const recommendationContract = c.router({
  getRecommendation: {
    method: 'POST',
    path: '/animals/:animalId/recommendations/feeding-plans',
    pathParams: c.type<{
      animalId: string
    }>(),
    responses: {
      200: c.type<FeedingPlansRecommendationOutput>(),
      500: ErrorResponseSchema,
    },
    body: z.object({
      preferredProductProportions: z
        .array(
          z.object({
            productKey: z.string(),
            proportionWeight: z.number().int().gte(0),
          }),
        )
        .optional(),
      servingSize: z.enum(SERVING_SIZE_OPTIONS).optional(),
    }),
  },
})
