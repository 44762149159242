<script setup lang="ts">
import ViewLayout from '@/components/ViewLayout.vue'
import { PawHeading } from '@lyka-pet-food/lyka-common'
import { computed, withDefaults } from 'vue'
import StickyBottomGeneral from '@/components/stickyNav/StickyBottomGeneral.vue'
import { FeedingPlan } from '@repo/api-types/src/generated/recommendation'
import moment from 'moment-timezone'

const props = withDefaults(
  defineProps<{
    dogName?: string
    loading?: boolean
    nextDeliveryDate?: Date
    currentRecommendationPlan: FeedingPlan
    selectedRecommendationPlan: FeedingPlan
  }>(),
  {
    loading: false,
  },
)

const nextDeliveryDateInString = computed<string>(() =>
  props.nextDeliveryDate ? moment(props.nextDeliveryDate).tz(moment.tz.guess()).format('ddd, DD MMM') : '',
)

const currentPouchSize = computed(() => props.currentRecommendationPlan.quantities[0].variant.skuSize)
const currentMeals = computed(() =>
  props.currentRecommendationPlan.quantities
    .filter((quantity) => quantity.quantity >= 0)
    .map((quantity) => quantity.variant.productKey)
    .join(', '),
)
const selectedPouchSize = computed(() => props.selectedRecommendationPlan.quantities[0].variant.skuSize)
const selectedMeals = computed(() =>
  props.selectedRecommendationPlan.quantities
    .filter((quantity) => quantity.quantity >= 0)
    .map((quantity) => quantity.variant.productKey)
    .join(', '),
)

const emits = defineEmits<{
  (e: 'cancel'): void
  (e: 'submitPlan', plan: string): void
}>()
</script>

<template>
  <ViewLayout>
    <div class="tw-flex tw-max-w-[1140px] tw-flex-col tw-flex-nowrap tw-space-y-8 tw-pb-[150px]">
      <div class="tw-flex tw-flex-row tw-items-start tw-self-start">
        <img
          src="@/assets/icons/chevron-left.svg"
          class="tw-cursor-pointer tw-pr-4 md:tw-pt-1"
          @click="emits('cancel')"
          alt="chevron-left"
        />
        <div class="tw-space-y-1">
          <PawHeading data-testid="confirm-heading" size="3xl"
            >Review {{ props.dogName }}'s new feeding plan</PawHeading
          >
        </div>
      </div>
      <div class="tw-flex tw-min-h-[300px] tw-flex-auto tw-items-start tw-justify-center">
        <div class="tw-flex tw-flex-auto tw-flex-col tw-items-center tw-py-10">
          <div class="tw-px-2 tw-py-1">Current</div>
          <div class="tw-pt-5 tw-text-xl">
            <span class="tw-font-bold">{{ currentRecommendationPlan.gramsPerDay }}g</span>
            per day
          </div>
          <div class="tw-py-5">
            <div class="tw-text-lg tw-font-bold">${{ currentRecommendationPlan.pricePerDay.toFixed(2) }} per day</div>
            <div class="tw-text-sm">
              ${{ currentRecommendationPlan.price.toFixed(2) }} every
              {{ currentRecommendationPlan.deliveryFrequencyWeeks }} week{{
                currentRecommendationPlan.deliveryFrequencyWeeks > 1 ? 's' : ''
              }}
            </div>
          </div>
          <div class="tw-flex tw-flex-col tw-flex-nowrap tw-items-center tw-justify-center">
            <div class="tw-text-lg">
              <span class="tw-font-bold">{{ currentRecommendationPlan.totalQuantity }}</span>
              pouches
            </div>
            <div class="tw-text-sm">{{ currentPouchSize }}g</div>
          </div>
          <div class="tw-p-5 tw-max-w-[300px] tw-text-lg tw-font-bold tw-text-center tw-capitalize">
            {{ currentMeals }}
          </div>
        </div>
        <div
          class="tw-flex tw-flex-auto tw-flex-col tw-items-center tw-rounded-2xl tw-bg-surface-brand-secondary tw-py-10"
        >
          <div class="tw-rounded-100 tw-bg-surface-brand-accent tw-px-2 tw-py-1 tw-text-white">New</div>
          <div class="tw-pt-5 tw-text-xl">
            <span class="tw-font-bold">{{ selectedRecommendationPlan.gramsPerDay }}g</span>
            per day
          </div>
          <div class="tw-py-5">
            <div class="tw-text-lg tw-font-bold">${{ selectedRecommendationPlan.pricePerDay.toFixed(2) }} per day</div>
            <div class="tw-text-sm">
              ${{ selectedRecommendationPlan.price.toFixed(2) }} every
              {{ selectedRecommendationPlan.deliveryFrequencyWeeks }} week{{
                selectedRecommendationPlan.deliveryFrequencyWeeks > 1 ? 's' : ''
              }}
            </div>
          </div>
          <div class="tw-flex tw-flex-col tw-flex-nowrap tw-items-center tw-justify-center">
            <div class="tw-text-lg">
              <span class="tw-font-bold">{{ selectedRecommendationPlan.totalQuantity }}</span>
              pouches
            </div>
            <div class="tw-text-sm">{{ selectedPouchSize }}g</div>
          </div>
          <div class="tw-mt-5 tw-px-8 tw-max-w-[300px] tw-text-lg tw-font-bold tw-text-center tw-capitalize">
            {{ selectedMeals }}
          </div>
        </div>
      </div>
      <div
        class="tw-m-5 tw-flex tw-flex-row tw-flex-nowrap tw-items-center tw-justify-start tw-rounded-2xl tw-border-[1px] tw-border-content-primary tw-p-5"
      >
        <img src="@/assets/icons/info-primary.svg" class="tw-h-[15px] tw-w-[15px] tw-cursor-pointer" alt="info" />
        <div class="tw-ml-2 tw-text-sm">
          Your new delivery schedule is
          <span class="tw-font-bold"
            >every {{ selectedRecommendationPlan.deliveryFrequencyWeeks }} week{{
              selectedRecommendationPlan.deliveryFrequencyWeeks > 1 ? 's' : ''
            }}</span
          >. This applies to all dogs.
        </div>
      </div>
      <div class="tw-px-5 tw-text-center tw-text-sm">
        These changes will take effect on your next order, being delivered on
        <span class="tw-font-bold">{{ nextDeliveryDateInString }}</span
        >.
      </div>
    </div>
    <template #postView>
      <StickyBottomGeneral
        :loading="loading"
        :confirm-text="'Confirm changes'"
        :confirm-disabled="loading"
        @cancel="emits('cancel')"
        @confirm="emits('submitPlan', '')"
      />
    </template>
  </ViewLayout>
</template>
