import { initContract } from '@ts-rest/core'
import { ErrorResponseSchema } from './general'
import { EnrichedOrder } from '@repo/api-types/src/order/types'

const c = initContract()

export const orderContract = c.router(
  {
    getOrders: {
      method: 'GET',
      path: '/orders',
      responses: {
        200: c.type<{ pastOrders: EnrichedOrder[]; currentOrders: EnrichedOrder[]; futureOrders: EnrichedOrder[] }>(),
      },
    },
  },
  {
    commonResponses: {
      400: ErrorResponseSchema,
      401: ErrorResponseSchema,
      404: ErrorResponseSchema,
      500: ErrorResponseSchema,
    },
  },
)
