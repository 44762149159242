<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { RetrieveDogReturnDto } from '@repo/api-types/src/generated/entities'
import { birthdayToAge } from '@/utils/birthdayToAge'
import { IconPencilSquare, IconPlus } from '@lyka-pet-food/lyka-common'
import { useMobile } from '@/composables/useMobile'

const props = withDefaults(
  defineProps<{
    pets: RetrieveDogReturnDto[]
    defaultIndex?: number
  }>(),
  {
    defaultIndex: 0,
  },
)

const emit = defineEmits<{
  (e: 'scrollToItem', index: number): void
  (e: 'editPet', pet: RetrieveDogReturnDto): void
}>()

const activeIndex = ref(props.defaultIndex)
const isMobile = useMobile().isMobile

const scrollToItem = (
  index: number,
  behavior: ScrollBehavior = 'smooth',
  inline: ScrollLogicalPosition = 'nearest',
) => {
  if (index === props.pets.length) {
    return
  }

  if (isMobile.value) {
    const item = document.getElementById('tab-' + index)
    item?.scrollIntoView({
      behavior: behavior,
      inline: 'center',
      block: 'center',
    })
  }
  activeIndex.value = index
  emit('scrollToItem', index)
}

onMounted(() => {
  setTimeout(() => {
    scrollToItem(activeIndex.value, 'instant')
  }, 1000)
})
</script>

<template>
  <div class="tw-flex tw-w-full tw-justify-center">
    <div
      class="tw-no-scrollbar tw-flex tw-min-h-[350px] tw-w-screen tw-cursor-pointer tw-items-center tw-gap-x-4 tw-overflow-x-scroll lg:tw-justify-center"
    >
      <div
        v-for="(pet, index) in props.pets"
        :key="index"
        :id="'tab-' + index"
        @click="scrollToItem(index)"
        class="tw-carousel-transition tw-rounded-xl tw-bg-surface-light tw-px-1.5 tw-pb-1 tw-pt-1.5 tw-text-center tw-font-callout tw-shadow-xl"
        :class="[
          [activeIndex === index ? ['-tw-rotate-3', 'tw-min-w-[153px]'] : ['tw-rotate-[4deg]', 'tw-min-w-[100px]']],
          [index === 0 ? 'tw-ml-6' : ''],
        ]"
      >
        <div class="tw-relative">
          <img class="tw-h-auto tw-w-full tw-rounded-xl" src="@/assets/images/pets/dog-photo-test-2.png" />
          <div
            v-if="index === activeIndex"
            class="tw-absolute tw-bottom-0 tw-right-0 tw-cursor-pointer tw-rounded-tl-2xl tw-bg-surface-brand-secondary"
          >
            <IconPencilSquare class="tw-mb-1 tw-ml-2 tw-mr-1 tw-mt-2" @click.stop="emit('editPet', pet)" />
          </div>
        </div>
        <div class="tw-pt-2 tw-font-bold" :class="index !== activeIndex && 'tw-text-body-xs'">
          {{ pet.name }}
        </div>
        <div v-if="index === activeIndex" class="tw-text-body-xs">
          {{ birthdayToAge(pet.dateOfBirth) }}
        </div>
        <div v-if="index === activeIndex" class="tw-text-body-xs">
          {{ pet.breeds?.join(', ') }}
        </div>
      </div>

      <div
        :id="'tab-' + props.pets.length"
        @click="scrollToItem(props.pets.length)"
        class="tw-carousel-transition tw-mr-12 tw-rounded-xl tw-bg-surface-light tw-px-1.5 tw-pb-1 tw-pt-1.5 tw-text-center tw-font-callout tw-shadow-xl"
        :class="[
          [
            activeIndex === props.pets.length
              ? ['-tw-rotate-3', 'tw-min-w-[153px]']
              : ['tw-rotate-[4deg]', 'tw-min-w-[100px]'],
          ],
          [props.pets.length === 0 ? 'tw-ml-12' : ''],
        ]"
      >
        <div
          class="tw-flex tw-w-full tw-items-center tw-justify-center tw-rounded-xl tw-bg-surface-brand-secondary"
          :class="activeIndex === props.pets.length ? 'tw-h-[153px]' : 'tw-h-[93px]'"
        >
          <IconPlus class="tw-w-auto" :class="[activeIndex === props.pets.length ? '!tw-h-[100px]' : '!tw-h-[45px]']" />
        </div>

        <div class="tw-pt-2 tw-font-bold" :class="props.pets.length !== activeIndex && 'tw-text-body-xs'">
          Add a dog
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.tw-no-scrollbar::-webkit-scrollbar {
  display: none;
}
.tw-no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.tw-carousel-transition {
  @apply tw-transition-all tw-duration-300;
}
</style>
