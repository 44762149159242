<script setup lang="ts">
import { RetrieveDogReturnDto } from '@repo/api-types/src/generated/entities'
import { PawHeading, PawButtonText, PawDivider } from '@lyka-pet-food/lyka-common'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useSubscriptionStore } from '@/stores/subscriptions'
import { useProductStore } from '@/stores/products'

const router = useRouter()

const props = defineProps<{
  dog: RetrieveDogReturnDto
}>()

const emit = defineEmits<{
  (e: 'editMeals', dog: RetrieveDogReturnDto): void
}>()

const subscriptionStore = useSubscriptionStore()
const productStore = useProductStore()

const subscription = computed(() => subscriptionStore.userActiveSubscription)

const feedingPlan = computed(() => subscription.value?.feedingPlans?.find((x) => x.animalId === props.dog?.identifier))

const servingSize = computed(() => {
  const match = feedingPlan.value?.currentDailyServingSizeInGrams.match(/[1-9]\d*/)
  if (match) {
    return match[0]
  }
})

const dogMeals = computed(() => {
  const meals = feedingPlan.value?.skus?.map(
    (productMeal) => productStore.getProductByProductVariantId(productMeal.productVariantId)?.name,
  )

  if (!meals) return ''

  if (meals?.length <= 2) return meals?.join(' & ')

  return meals?.slice(0, -1).join(', ') + ' & ' + meals[meals.length - 1]
})

const changeServingSize = async () => {
  if (props.dog?.identifier && feedingPlan.value?.id) {
    await router.push({
      name: 'edit-serving-size',
      params: {
        dogId: props.dog.identifier,
        feedingPlanId: feedingPlan.value.id,
      },
    })
  }
}
</script>

<template>
  <div class="tw-w-full">
    <PawHeading size="2xl" type="subheading">{{ props.dog?.name }}'s plan</PawHeading>
  </div>

  <div
    class="tw-flex tw-flex-col md:tw-flex-row tw-bg-content-invert tw-border-color-brand-secondary tw-rounded-xl tw-border tw-custom-shadow tw-p-5"
  >
    <div class="tw-full md:tw-w-1/2 md:tw-border-r md:tw-mr-5">
      <div class="tw-flex tw-justify-between">
        <div class="tw-flex tw-flex-col tw-space-y-1 tw-w-[218px] tw-text-body-sm">
          <div>Meals</div>
          <div class="tw-text-body-md">{{ dogMeals }}</div>
        </div>

        <PawButtonText
          class="tw-text-surface-brand-accent"
          :disabled="!subscription || !feedingPlan"
          @click="emit('editMeals', dog)"
        >
          Edit
        </PawButtonText>
      </div>
    </div>

    <PawDivider class="tw-my-5 md:tw-hidden" />

    <div class="tw-full md:tw-w-1/2">
      <div class="tw-flex tw-justify-between">
        <div class="tw-flex tw-flex-col tw-space-y-1 tw-w-[218px] tw-text-body-sm">
          <div>Daily serving size</div>
          <div class="tw-text-body-md">{{ servingSize }}g / day</div>
        </div>

        <PawButtonText
          class="tw-text-surface-brand-accent"
          :disabled="!subscription || !feedingPlan"
          @click="changeServingSize"
        >
          Edit
        </PawButtonText>
      </div>
    </div>
  </div>
</template>
<style scoped lang="postcss">
.tw-custom-shadow {
  @apply tw-shadow-[2px_6px_20px_0_rgba(0,0,0,0.08)];
}
</style>
