import { useClient } from '@/composables/useClient.js'
import type {
  FeedingPlanServingSize,
  FeedingPlanType,
  UpdateSubscriptionBodyExtra,
  UpdateSubscriptionBodyFeedingPlanSku,
} from '@lyka-pet-food/contracts/services/subscription'
import { OrderDto } from '@repo/api-types'
import { EnrichedOrder } from '@repo/api-types/src/order/types.js'
import type { Subscription } from '@repo/api-types/src/subscription/types.js'

export const useSubscriptions = () => {
  const getSubscriptions = async (): Promise<{
    error?: string | null
    subscriptions?: Subscription[]
    activeSubscription?: Subscription
  }> => {
    const client = await useClient()
    const { status, body } = await client.subscription.getSubscriptions()
    if (status === 200) {
      return {
        subscriptions: body.subscriptions,
        activeSubscription: body.subscriptions.find((subscription) => subscription.status === 'active'),
      }
    }
    return { error: (body as unknown as { error?: string }).error || 'Unspecified server error' }
  }

  const updateSubscriptionExtra = async (
    subscriptionId: string,
    updatedSubscriptions: UpdateSubscriptionBodyExtra[],
  ): Promise<{
    error?: string | null
    subscription?: Subscription
  }> => {
    const client = await useClient()
    const { status, body } = await client.subscription.updateSubscriptionExtras({
      params: {
        subscriptionId,
      },
      body: updatedSubscriptions,
    })
    if (status === 200) {
      return { subscription: body.subscription }
    }
    return { error: (body as unknown as { error?: string }).error || 'Unspecified server error' }
  }

  const updateSubscriptionMeals = async (
    subscriptionId: string,
    feedingPlanId: string,
    updatedMeals: UpdateSubscriptionBodyFeedingPlanSku[],
  ): Promise<{
    error?: string | null
    subscription?: Subscription
  }> => {
    const client = await useClient()
    const { status, body } = await client.subscription.updateSubscriptionRecipes({
      params: {
        subscriptionId,
        feedingPlanId,
      },
      body: updatedMeals,
    })
    if (status === 200) {
      return { subscription: body.subscription }
    }
    return { error: (body as unknown as { error?: string }).error || 'Unspecified server error' }
  }

  const updateSubscriptionFeedingPlan = async (
    servingSize: FeedingPlanServingSize,
    subscriptionId: string,
    feedingPlanId: string,
  ): Promise<{
    error?: string | null
    subscription?: Subscription
  }> => {
    const client = await useClient()
    const { status, body } = await client.subscription.updateSubscriptionServingSize({
      body: {
        servingSize,
        subscriptionId,
        feedingPlanId,
      },
    })
    if (status === 200) {
      return { subscription: body.subscription }
    }
    return { error: (body as unknown as { error?: string }).error || 'Unspecified server error' }
  }

  const updateSubscriptionDeliveryDate = async (
    orderId: string,
    deliveryDate: string,
  ): Promise<{
    error?: string | null
    orders?: OrderDto[]
  }> => {
    const client = await useClient()
    const { status, body } = await client.subscription.updateSubscriptionDeliveryDate({
      body: {
        orderId,
        deliveryDate,
      },
    })
    if (status === 200) {
      return { orders: body.orders }
    }
    return { error: (body as unknown as { error?: string }).error || 'Unspecified server error' }
  }

  const updateSubscriptionFeedingPlanType = async (
    subscriptionId: string,
    feedingPlanType: FeedingPlanType,
  ): Promise<{
    error?: string | null
    subscription?: Subscription
  }> => {
    const client = await useClient()
    const { status, body } = await client.subscription.updateSubscriptionFeedingPlanType({
      body: {
        subscriptionId,
        feedingPlanType,
      },
    })
    if (status === 200) {
      return { subscription: body.subscription }
    }
    return { error: (body as unknown as { error?: string }).error || 'Unspecified server error' }
  }

  return {
    getSubscriptions,
    updateSubscriptionExtra,
    updateSubscriptionMeals,
    updateSubscriptionFeedingPlan,
    updateSubscriptionDeliveryDate,
    updateSubscriptionFeedingPlanType,
  }
}
