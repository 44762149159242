<script setup lang="ts">
import StickyBottomGeneral from '@/components/stickyNav/StickyBottomGeneral.vue'
import ViewLayout from '@/components/ViewLayout.vue'
import { RetrieveDogReturnDto } from '@repo/api-types/src/generated/entities'
import { PawHeading, IconCheck, PawBadge, IconExclamationCircle, PawDivider } from '@lyka-pet-food/lyka-common'
import { ref, computed, onBeforeMount } from 'vue'
import { FeedingPlan, ProductVariantQuantity } from '@repo/api-types/src/generated/recommendation'
import { dateToLocalDateFull } from '@/utils/dateToLocalString'
import { SERVING_SIZE_OPTIONS } from '@lyka-pet-food/lyka-shared-types/lib/types/ProductService'
import { useSubscriptionStore } from '@/stores/subscriptions'
import { useOrderStore } from '@/stores/orders'
import { useSubscriptions } from '@/composables/useSubscriptions'
import { FEES } from '@lyka-pet-food/contracts/services/pricing'
import { FeedingPlanType } from '@lyka-pet-food/contracts/services/subscription'

const props = defineProps<{
  dog: RetrieveDogReturnDto
  feedingPlans: FeedingPlan[]
}>()

const subscriptionStore = useSubscriptionStore()
const orderStore = useOrderStore()

const fullPlanSelected = ref(true)
const reviewPlan = ref(false)

const emits = defineEmits<{
  (e: 'cancelEditMealPlan'): void
  (e: 'updateMealPlanStatus', loading: boolean, successState: boolean): void
}>()

const fullBowlPlan = computed(() => props.feedingPlans.find((plan) => plan.feedingPlanType === 'full_bowl'))

const halfBowlPlan = computed(() => props.feedingPlans.find((plan) => plan.feedingPlanType === 'half_bowl'))

const starterBox = computed(() => props.feedingPlans.find((plan) => plan.feedingPlanType === 'starter_box'))

const trialBox = computed(() => props.feedingPlans.find((plan) => plan.feedingPlanType === 'trial_box'))

const feedingPlan = computed(() => subscriptionStore.getFeedingPlanByAnimalId(props.dog?.identifier))

const currentSubscription = computed(() => subscriptionStore.userActiveSubscription)

const currentPlan = computed(() => {
  switch (feedingPlan.value?.type) {
    case 'full_bowl':
      return fullBowlPlan.value
    case 'half_bowl':
      return halfBowlPlan.value
    // case 'starter_box':
    //   return starterBox.value
    // case 'trial_box':
    //   return trialBox.value
  }
})

const recommendedGramsPerDay = computed(() => currentPlan.value?.recommendedGramsPerDay)
const fullBowlCoverPercentage = computed(
  () =>
    recommendedGramsPerDay.value &&
    fullBowlPlan.value?.gramsPerDay &&
    Math.round((fullBowlPlan.value.gramsPerDay * 100) / recommendedGramsPerDay.value),
)
const halfBowlCoverPercentage = computed(
  () =>
    recommendedGramsPerDay.value &&
    halfBowlPlan.value?.gramsPerDay &&
    Math.round((halfBowlPlan.value.gramsPerDay * 100) / recommendedGramsPerDay.value),
)

const newPlan = computed(() => (fullPlanSelected.value ? fullBowlPlan.value : halfBowlPlan.value))

const currentPlanCoverPercentage = computed(
  () =>
    recommendedGramsPerDay.value &&
    currentPlan.value?.gramsPerDay &&
    Math.round((currentPlan.value.gramsPerDay * 100) / recommendedGramsPerDay.value),
)
const newPlanCoverPercentage = computed(
  () =>
    recommendedGramsPerDay.value &&
    newPlan.value?.gramsPerDay &&
    Math.round((newPlan.value.gramsPerDay * 100) / recommendedGramsPerDay.value),
)

// Update serving size always refer to full bowl plan serving size
const newServingSizeOption = computed(() =>
  SERVING_SIZE_OPTIONS.find(
    (servingSize) =>
      fullBowlPlan.value?.gramsPerDay && parseInt(servingSize) === parseInt(String(fullBowlPlan.value?.gramsPerDay)),
  ),
)

const currentPlanSku = computed(
  () => currentPlan.value?.quantities.find((x: ProductVariantQuantity) => x.quantity !== 0)?.variant.skuSize,
)

const newPlanSku = computed(
  () => newPlan.value?.quantities.find((x: ProductVariantQuantity) => x.quantity !== 0)?.variant.skuSize,
)

const nextOrderDeliveryDate = computed(() => dateToLocalDateFull(orderStore.futureOrders[0].deliveryDate))

const reviewPlanButtonDisabled = computed(() => currentPlan.value?.name === newPlan.value?.name)

const onGoBack = () => {
  if (reviewPlan.value) {
    reviewPlan.value = false
  } else {
    emits('cancelEditMealPlan')
  }
}

const onConfirm = async () => {
  if (reviewPlan.value === false) {
    reviewPlan.value = true
  } else {
    await onConfirmUpdateMealPlan()
  }
}

const onConfirmUpdateMealPlan = async () => {
  emits('updateMealPlanStatus', true, false)

  if (!currentSubscription.value?.id) {
    alert('No subscription found')
    return
  }

  if (!newPlan.value?.feedingPlanType || !newServingSizeOption.value) {
    alert(`New plan type not found ${newPlan.value?.feedingPlanType} ${newPlan.value?.gramsPerDay}`)
    return
  }

  const { error } = await useSubscriptions().updateSubscriptionFeedingPlanType(
    currentSubscription.value.id,
    newPlan.value.feedingPlanType === 'full_bowl' ? FeedingPlanType.full_bowl : FeedingPlanType.half_bowl,
  )

  if (!error) {
    emits('updateMealPlanStatus', false, true)
  } else {
    alert(error)
  }
}

onBeforeMount(() => {
  if (feedingPlan.value?.type === 'half_bowl') {
    fullPlanSelected.value = false
  }
})
</script>

<template>
  <ViewLayout>
    <div class="tw-flex tw-w-full tw-max-w-[1140px] tw-flex-col tw-space-y-8 tw-pb-48">
      <div class="tw-flex tw-w-full tw-flex-row tw-items-start tw-self-start">
        <img src="@/assets/icons/chevron-left.svg" class="tw-cursor-pointer tw-pr-4 md:tw-pt-1" @click="onGoBack" />
        <div class="tw-space-y-1">
          <PawHeading size="3xl" v-if="!reviewPlan">Update {{ props.dog.name }}'s feeding plan</PawHeading>
          <PawHeading size="3xl" v-if="reviewPlan">Review {{ props.dog.name }}'s new feeding plan</PawHeading>
        </div>
      </div>

      <div
        v-if="!reviewPlan"
        class="tw-space-y-5 tw-pt-8 lg:tw-flex lg:tw-justify-center lg:tw-space-x-5 lg:tw-space-y-0 lg:tw-pt-12"
      >
        <div class="tw-plan-wrapper" @click="() => (fullPlanSelected = true)">
          <div :class="fullPlanSelected ? 'tw-selected' : 'tw-unselected'">
            <div class="tw-absolute -tw-left-0.5 -tw-top-9 -tw-z-10 lg:tw-hidden" v-if="fullPlanSelected">
              <div class="tw-w-fit tw-rounded-t-2xl tw-bg-surface-brand-accent tw-p-2 tw-pb-3 tw-text-content-invert">
                <img src="@/assets/images/dogs/popular-star.svg" class="tw-inline tw-pr-2" />Most popular
              </div>
            </div>

            <div class="tw-absolute -tw-top-4 tw-hidden tw-w-full tw-justify-center lg:tw-flex" v-if="fullPlanSelected">
              <div class="tw-w-fit tw-rounded-2xl tw-bg-surface-brand-accent tw-p-1 tw-px-4 tw-text-content-invert">
                <img src="@/assets/images/dogs/popular-star.svg" class="tw-inline tw-pr-2" />Most popular
              </div>
            </div>

            <IconCheck class="tw-icon-check" v-if="fullPlanSelected" />

            <div class="tw-plan-container">
              <div class="tw-plan-img-container">
                <img src="@/assets/images/dogs/full-bowl.png" />
              </div>

              <div class="tw-plan-content-container">
                <PawHeading size="2xl">Full Bowl Plan</PawHeading>
                <div>Complete and balanced diet</div>

                <hr class="tw-plan-divider-desktop" />

                <div class="tw-font-bold">
                  Delivered every
                  {{ fullBowlPlan?.deliveryFrequencyWeeks }} week{{
                    fullBowlPlan && Number(fullBowlPlan?.deliveryFrequencyWeeks) > 1 ? 's' : ''
                  }}
                </div>
                <div class="tw-plan-underline-text">{{ fullBowlCoverPercentage }}% of their diet</div>

                <hr class="tw-plan-divider-desktop" />
              </div>
            </div>

            <div class="tw-plan-price-mobile">${{ fullBowlPlan?.pricePerDay.toFixed(2) }} per day</div>
            <PawHeading size="2xl" class="tw-plan-price-desktop"
              >${{ fullBowlPlan?.pricePerDay.toFixed(2) }}/day</PawHeading
            >
          </div>
        </div>

        <div class="tw-plan-wrapper" @click="() => (fullPlanSelected = false)">
          <div :class="fullPlanSelected ? 'tw-unselected' : 'tw-selected'">
            <IconCheck class="tw-icon-check" v-if="!fullPlanSelected" />

            <div class="tw-plan-container">
              <div class="tw-plan-img-container">
                <img src="@/assets/images/dogs/half-bowl.png" />
              </div>

              <div class="tw-plan-content-container">
                <PawHeading size="2xl">Half Bowl Plan</PawHeading>
                <div>Mix Lyka with other foods</div>

                <hr class="tw-plan-divider-desktop" />

                <div class="tw-font-bold">
                  Delivered every
                  {{ halfBowlPlan?.deliveryFrequencyWeeks }} week{{
                    halfBowlPlan && Number(halfBowlPlan?.deliveryFrequencyWeeks) > 1 ? 's' : ''
                  }}
                </div>
                <div class="tw-plan-underline-text">{{ halfBowlCoverPercentage }}% of their diet</div>

                <hr class="tw-plan-divider-desktop" />
              </div>
            </div>

            <div class="tw-plan-price-mobile">${{ halfBowlPlan?.pricePerDay.toFixed(2) }} per day</div>
            <PawHeading size="2xl" class="tw-plan-price-desktop"
              >${{ halfBowlPlan?.pricePerDay.toFixed(2) }}/day</PawHeading
            >
          </div>
        </div>
      </div>

      <div v-if="reviewPlan" class="tw-space-y-10 tw-self-center lg:tw-w-[620px]">
        <div class="tw-flex tw-justify-center">
          <div class="tw-w-1/2 tw-space-y-5 tw-p-6 tw-text-center">
            <PawBadge text="Current" size="md" />
            <div class="tw-flex tw-justify-center">
              <img src="@/assets/images/dogs/full-bowl.png" class="tw-h-[72px] tw-w-auto" />
            </div>
            <div>
              <div class="tw-review-plan-heading-lg">
                <span>{{ currentPlan?.gramsPerDay }}g</span> a day
              </div>
              <div>{{ currentPlan?.name }} Plan</div>
              <div>{{ currentPlanCoverPercentage }}% of their diet</div>
            </div>
            <div>
              <div class="tw-review-plan-heading-md">
                <span>${{ currentPlan?.pricePerDay.toFixed(2) }} per day</span>
              </div>
              <div>${{ currentPlan?.price.toFixed(2) }} every 2 weeks</div>
            </div>
            <div>
              <div class="tw-review-plan-heading-md">
                <span>{{ currentPlan?.totalQuantity }}</span> pouches
              </div>
              <div>{{ currentPlanSku }}g</div>
            </div>
          </div>

          <div class="tw-w-1/2 tw-space-y-5 tw-rounded-3xl tw-bg-surface-brand-secondary tw-p-6 tw-text-center">
            <PawBadge text="New" size="md" class="!tw-bg-surface-brand-accent !tw-text-content-invert" />
            <div class="tw-flex tw-justify-center">
              <img src="@/assets/images/dogs/half-bowl.png" class="tw-h-[72px] tw-w-auto" />
            </div>
            <div>
              <div class="tw-review-plan-heading-lg">
                <span>{{ newPlan?.gramsPerDay }}g</span> a day
              </div>
              <div>{{ newPlan?.name }} Plan</div>
              <div>{{ newPlanCoverPercentage }}% of their diet</div>
            </div>
            <div>
              <div class="tw-review-plan-heading-md">
                <span>${{ newPlan?.pricePerDay.toFixed(2) }} per day</span>
              </div>
              <div>
                ${{ newPlan?.price.toFixed(2) }} every {{ newPlan?.deliveryFrequencyWeeks }} week{{
                  newPlan && newPlan?.deliveryFrequencyWeeks > 1 ? 's' : ''
                }}
              </div>
            </div>
            <div>
              <div class="tw-review-plan-heading-md">
                <span>{{ newPlan?.totalQuantity }}</span> pouches
              </div>
              <div>{{ newPlanSku }}g</div>
            </div>
          </div>
        </div>

        <div class="">
          <div class="tw-border-primary tw-rounded-xl tw-border tw-bg-[#F3F4F1] tw-p-4">
            <IconExclamationCircle class="tw-mr-3 tw-inline" />Your new delivery schedule is
            <span class="tw-font-bold"
              >every {{ newPlan?.deliveryFrequencyWeeks }} week{{
                newPlan && newPlan?.deliveryFrequencyWeeks > 1 ? 's' : ''
              }}</span
            >. This applies to all dogs.
          </div>
        </div>

        <div>
          These changes will take effect on your next order, being delivered on
          <span class="tw-font-bold">{{ nextOrderDeliveryDate }}</span
          >.
        </div>
      </div>
    </div>

    <template #postView>
      <StickyBottomGeneral
        :confirmText="reviewPlan ? 'Confirm changes' : 'Review changes'"
        :confirm-disabled="reviewPlanButtonDisabled"
        @cancel="emits('cancelEditMealPlan')"
        @confirm="onConfirm"
      />
    </template>
  </ViewLayout>
</template>

<style scoped>
.tw-selected {
  @apply tw-border-focus tw-border-2 tw-bg-[#F5FAF4];
}

.tw-unselected {
  @apply tw-border-secondary tw-border-2 tw-bg-surface-light;
}

.tw-plan-wrapper {
  @apply tw-w-full tw-cursor-pointer lg:tw-w-[285px];
}

.tw-plan-wrapper > div {
  @apply tw-relative tw-w-full tw-rounded-xl;
}

.tw-icon-check {
  @apply tw-absolute tw-right-4 tw-top-5 tw-text-content-accent;
}

.tw-plan-container {
  @apply tw-flex tw-border-b tw-border-neutral-300 tw-pb-4 tw-pt-7 lg:tw-flex-col lg:tw-border-b-0 lg:tw-pb-0 lg:tw-text-center;
}

.tw-plan-img-container {
  @apply tw-w-1/3 tw-px-3 lg:tw-mb-4 lg:tw-flex lg:tw-h-[134px] lg:tw-w-full lg:tw-justify-center;
}

.tw-plan-img-container img {
  @apply tw-max-h-[134px] lg:tw-h-full lg:tw-w-auto;
}

.tw-plan-content-container {
  @apply tw-flex tw-w-2/3 tw-flex-col tw-space-y-1 lg:tw-w-full lg:tw-justify-center;
}

.tw-plan-underline-text {
  @apply tw-cursor-pointer tw-font-bold tw-text-content-accent tw-underline;
}

.tw-plan-divider-desktop {
  @apply !tw-my-4 tw-hidden tw-w-4/5 tw-self-center tw-bg-neutral-300 lg:tw-block;
}

.tw-plan-price-mobile {
  @apply tw-mr-4 tw-py-3 tw-text-end tw-font-bold lg:tw-hidden lg:tw-pt-0;
}

.tw-plan-price-desktop {
  @apply tw-hidden tw-pb-6 tw-pt-2 tw-text-center lg:tw-block;
}

.tw-review-plan-heading-lg {
  @apply tw-text-body-lg;
}

.tw-review-plan-heading-md {
  @apply tw-text-body-md;
}

.tw-review-plan-heading-lg span {
  @apply tw-font-bold;
}

.tw-review-plan-heading-md span {
  @apply tw-font-bold;
}

.tw-review-plan-heading-lg ~ div {
  @apply tw-text-body-xs;
}

.tw-review-plan-heading-md ~ div {
  @apply tw-text-body-xs;
}
</style>
