import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useProducts } from '@/composables/useProducts'
import type { Product, ProductVariant } from '@repo/api-types/src/product/types'

export const useProductStore = defineStore('products', () => {
  const allProducts = ref<Product[]>()
  const allProductVariants = ref<ProductVariant[]>()
  const loading = ref(false)

  const load = async () => {
    loading.value = true
    const { products, productVariants, error } = await useProducts().getProducts()
    // TODO: Move to error  handling
    if (error) {
      console.error(error)
      loading.value = false
      return
    }
    allProducts.value = products
    allProductVariants.value = productVariants
    loading.value = false
  }

  const getProductById = (id: string) => {
    return allProducts.value?.find((product) => product?.id === id)
  }

  const getProductVariantById = (id: string) => {
    return allProductVariants.value?.find((productVariant) => productVariant.id === id)
  }

  const getImageUrlByProductId = (id: string) => {
    return getProductById(id)?.images.find((image) => image.imageType === 'icon')?.imageUrl
  }

  const getImageUrlByProductVariantId = (id: string) => {
    return getProductVariantById(id)?.images.find((image) => image.imageType === 'icon')?.imageUrl
  }

  const getQuantityTypeInGramByVariantId = (id: string) => {
    return getProductVariantById(id)?.dimension?.weightGrams ?? undefined
  }

  const getProductVariantByProductId = (id: string) => {
    return allProducts.value?.find((product) => product.id === id)?.variants?.[0]
  }

  const getProductVariantByProductIdPouchSize = (id: string, pouchSize: string) => {
    const pouchSizeInNumber = parseInt(pouchSize)
    return allProducts.value
      ?.find((product) => product.id === id)
      ?.variants?.find((variant) => variant.dimension?.weightGrams === pouchSizeInNumber)
  }

  const getProductByProductVariantId = (id: string) => {
    return allProducts.value?.find((product) => product.variants.find((variant) => variant.id === id))
  }

  return {
    loading,
    allProducts,
    allProductVariants,
    getImageUrlByProductId,
    getImageUrlByProductVariantId,
    getProductVariantById,
    getProductById,
    getQuantityTypeInGramByVariantId,
    getProductByProductVariantId,
    getProductVariantByProductId,
    getProductVariantByProductIdPouchSize,
    load,
  }
})
