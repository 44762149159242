export const shortenDogName = (dogNames: Array<string>): string => {
  if (dogNames.length === 0) return ''

  if (dogNames.length > 20) {
    return 'fur fam'
  }

  if (dogNames.length <= 2) {
    return dogNames.join(' & ')
  }

  return dogNames.slice(0, -1).join(', ') + ' & ' + dogNames[dogNames.length - 1]
}
