import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useSubscriptions } from '@/composables/useSubscriptions.js'
import type { Subscription } from '@repo/api-types/src/subscription/types.js'

export const useSubscriptionStore = defineStore('subscriptions', () => {
  const userSubscriptions = ref<Subscription[]>()
  const userActiveSubscription = ref<Subscription>()
  const loading = ref(false)

  const load = async () => {
    loading.value = true
    const { subscriptions, activeSubscription, error } = await useSubscriptions().getSubscriptions()
    // TODO: Move to error  handling
    if (error) {
      console.error(error)
      loading.value = false
      return
    }
    userSubscriptions.value = subscriptions
    userActiveSubscription.value = activeSubscription
    loading.value = false
  }

  const getFeedingPlanByAnimalId = (animalId: string) => {
    return userActiveSubscription.value?.feedingPlans!.find((feedingPlan) => feedingPlan.animalId === animalId)
  }

  return {
    loading,
    userSubscriptions,
    userActiveSubscription,
    getFeedingPlanByAnimalId,
    load,
  }
})
