import { useClient } from '@/composables/useClient'
import { ServingSizeOption } from '@lyka-pet-food/lyka-shared-types/lib/types/ProductService'
import { FeedingPlansRecommendationOutput, PreferProductProportion } from '@repo/api-types/src/generated/recommendation'

export const useRecommendation = () => {
  const getRecommendation = async (
    animalId: string,
    servingSize?: ServingSizeOption,
    preferredProductProportions?: PreferProductProportion[],
  ): Promise<{
    error?: string | null
    recommendation?: FeedingPlansRecommendationOutput
  }> => {
    const client = await useClient()
    const { status, body } = await client.recommendation.getRecommendation({
      params: {
        animalId: animalId,
      },
      body: {
        servingSize,
        preferredProductProportions: preferredProductProportions,
      },
    })

    let error = null
    if (status == 500) {
      error = body.error
      return { error }
    } else if (status == 200) {
      return {
        recommendation: body,
      }
    }

    return { error: 'Unspecified server error' }
  }

  return {
    getRecommendation,
  }
}
