import { useClient } from '@/composables/useClient'
import type { Product, ProductVariant } from '@repo/api-types/src/product/types'

export const useProducts = () => {
  const getProducts = async (): Promise<{
    error?: string | null
    products?: Product[]
    productVariants?: ProductVariant[]
  }> => {
    const client = await useClient()
    const { status, body } = await client.product.getProducts()
    if (status === 200) {
      return { products: body.products, productVariants: body.productVariants }
    }
    return { error: (body as unknown as { error?: string }).error || 'Unspecified server error' }
  }
  return {
    getProducts,
  }
}
