import { initContract } from '@ts-rest/core'
import { ErrorResponseSchema } from './general'
import { Product, ProductVariant } from '@repo/api-types/src/product/types'

const c = initContract()

export const productContract = c.router(
  {
    getProducts: {
      method: 'GET',
      path: '/products',
      responses: {
        200: c.type<{ products: Product[]; productVariants: ProductVariant[] }>(),
      },
    },
  },
  {
    commonResponses: {
      400: ErrorResponseSchema,
      401: ErrorResponseSchema,
      404: ErrorResponseSchema,
      500: ErrorResponseSchema,
    },
  },
)
