<script setup lang="ts">
import ViewLayout from '@/components/ViewLayout.vue'
import { withDefaults, ref, onMounted, computed } from 'vue'
import { PawHeading } from '@lyka-pet-food/lyka-common'
import TreatsItem from '@/components/TreatsItem.vue'
import { ProductType } from '@/types/products'
import StickyBottomAddProduct from '@/components/stickyNav/StickyBottomAddProduct.vue'
import { EnrichedOrder, OrderLineItem } from '@repo/api-types/src/order/types'
import { Product } from '@repo/api-types/src/product/types'
import { useProductStore } from '@/stores/products'

export type ProductToEdit = Product & {
  quantity: number
  price?: number
  subscriptionFrequency: number
}

const props = withDefaults(
  defineProps<{
    dogName?: string
    lineItems?: OrderLineItem[]
    productMeals?: Array<Product>
    order?: EnrichedOrder
  }>(),
  {},
)

const emits = defineEmits<{
  (e: 'cancelAddProducts'): void
  (e: 'confirmAddProducts', updatedProducts: Array<ProductToEdit>, order?: EnrichedOrder): void
}>()

const productStore = useProductStore()

const productExtras = computed(() => {
  return productStore.allProducts?.filter((x) => x?.type === ProductType.TREAT)
})

const productsToAdd = ref<Array<ProductToEdit>>(
  productExtras.value
    ? productExtras.value.map((product: Product) => ({
        ...product,
        quantity: 0,
        price: productStore.getProductVariantByProductId(product.id)?.basePrice ?? undefined,
        subscriptionFrequency: 0,
      }))
    : [],
)

const productSummary = computed(() => {
  return productsToAdd.value
    .filter((x) => x.quantity > 0)
    .map((x) => {
      return {
        title: x.name,
        quantity: x.quantity,
        price: x.price,
      }
    })
})

const onAddQuantity = (index: number): void => {
  productsToAdd.value[index].quantity++
}

const onRemoveQuantity = (index: number): void => {
  productsToAdd.value[index].quantity > 0 && productsToAdd.value[index].quantity--
}

const totalMealsQuantity = computed(() => {
  return props.lineItems?.reduce((a, b) => a + b.quantity, 0) || 0
})

const selectedMealsQuantity = computed(() => {
  return productsToAdd.value.reduce((a, b) => a + b.quantity, 0)
})

const updatedProducts = computed(() => {
  return productsToAdd.value
})

const deliveryDate = computed(() => {
  if (!props.order || !props.order.deliveryDate) return ''

  return new Date(props.order.deliveryDate).toLocaleDateString('en-AU', {
    day: 'numeric',
    month: 'short',
  })
})

const onUpdateFrequency = (value: number) => {
  productsToAdd.value.forEach((product) => {
    product.subscriptionFrequency = value
  })
}
</script>

<template>
  <ViewLayout>
    <div class="tw-max-w-[1140px] tw-space-y-8">
      <div class="tw-flex tw-flex-row tw-items-start tw-self-start">
        <img
          src="@/assets/icons/chevron-left.svg"
          class="tw-cursor-pointer tw-pr-4 md:tw-pt-1"
          @click="emits('cancelAddProducts')"
        />
        <div class="tw-space-y-1">
          <PawHeading size="3xl">Add extras for the {{ deliveryDate }} </PawHeading>
          <div class="tw-text-body-md tw-text-content-accent">Browse more extras</div>
        </div>
      </div>

      <div class="tw-w-full tw-space-y-4 tw-pb-64 md:tw-pb-0">
        <PawHeading size="3xl" type="subheading">Treats</PawHeading>
        <div
          class="-tw-mx-4 tw-mb-52 tw-grid tw-grid-cols-2 tw-gap-4 tw-rounded-3xl tw-p-4 md:tw-mx-0 md:tw-w-full md:tw-grid-cols-3 md:tw-gap-8 xl:tw-grid-cols-4 min-[1440px]:tw-p-8"
        >
          <div v-for="(product, index) in productsToAdd" :key="`meal-item-${index}`">
            <TreatsItem
              :product="product"
              @add-quantity="onAddQuantity(index)"
              @remove-quantity="onRemoveQuantity(index)"
            />
            <!-- <PawDivider v-if="index !== productsToAdd.length - 1" class="tw-my-4 md:tw-hidden" /> -->
          </div>
        </div>
      </div>
    </div>

    <template #postView>
      <StickyBottomAddProduct
        v-if="productSummary.length > 0"
        :items="productSummary"
        :order="props.order"
        @update-frequency="onUpdateFrequency"
        @confirm="emits('confirmAddProducts', updatedProducts, props.order)"
        @cancel="emits('cancelAddProducts')"
      />
    </template>
  </ViewLayout>
</template>
