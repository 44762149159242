<script setup lang="ts">
import ViewLayout from '@/components/ViewLayout.vue'
import { withDefaults, ref, computed } from 'vue'
import { PawHeading, PawDivider } from '@lyka-pet-food/lyka-common'
import StickyBottomGeneral from '@/components/stickyNav/StickyBottomGeneral.vue'
import StepButton from '@/components/StepButton.vue'
import { SERVING_SIZE_OPTIONS, ServingSizeOption } from '@lyka-pet-food/lyka-shared-types/lib/types/ProductService'

const props = withDefaults(
  defineProps<{
    dogName?: string
    loading?: boolean
    initialServingSize?: any
    recommendedServingSize?: any
  }>(),
  {
    loading: false,
  },
)

const initialIndex = ref<number>(SERVING_SIZE_OPTIONS.findIndex((size) => size === props.initialServingSize))

const currentIndex = ref<number>(SERVING_SIZE_OPTIONS.findIndex((size) => size === props.initialServingSize))

const indexDelta = computed<number>(() => currentIndex.value - initialIndex.value)

const message = computed<{
  amount: 'most' | 'some' | 'all'
  suggestion: string
}>(() => {
  if (indexDelta.value <= -2) {
    return {
      amount: 'some',
      suggestion: 'You know your dog best! Just keep an eye out on his weight and body condition.',
    }
  }
  if (indexDelta.value <= -1) {
    return {
      amount: 'most',
      suggestion: 'Room for treats and snacks!',
    }
  }
  if (indexDelta.value >= 1) {
    return {
      amount: 'all',
      suggestion: 'You know your dog best! Just keep an eye out on his weight and body condition.',
    }
  }
  return {
    amount: 'all',
    suggestion: 'We recommend mix-feeding with other foods.',
  }
})

const currentServingSize = computed<ServingSizeOption>(() => SERVING_SIZE_OPTIONS[currentIndex.value])

const emits = defineEmits<{
  (e: 'cancel'): void
  (e: 'reviewChanges', servingSize: ServingSizeOption): void
}>()

const onIncreaseServingSize = () =>
  (currentIndex.value = Math.min(SERVING_SIZE_OPTIONS.length - 1, currentIndex.value + 1))

const onDecreaseServingSize = () => (currentIndex.value = Math.max(0, currentIndex.value - 1))
</script>

<template>
  <ViewLayout>
    <div class="tw-flex tw-max-w-[1140px] tw-flex-col tw-flex-nowrap tw-space-y-8">
      <div class="tw-flex tw-flex-row tw-items-start tw-self-start">
        <img
          src="@/assets/icons/chevron-left.svg"
          class="tw-cursor-pointer tw-pr-4 md:tw-pt-1"
          @click="emits('cancel')"
          alt="chevron-left"
        />
        <div class="tw-space-y-1">
          <PawHeading data-testid="serving-size-heading" size="3xl"
            >Choose {{ props.dogName }}'s daily amount of Lyka</PawHeading
          >
        </div>
      </div>
      <div class="tw-flex tw-h-[300px] tw-flex-auto tw-items-center tw-justify-center">
        <div>
          <StepButton
            data-testid="decremental-button"
            :action-type="'decrement'"
            @update-value="onDecreaseServingSize"
          />
        </div>
        <div class="tw-flex tw-flex-auto tw-flex-col tw-flex-nowrap tw-items-center">
          <img class="tw-mt-8 tw-max-w-[250px]" src="@/assets/images/meals/bowl-general.png" alt="bowl-general" />
          <h3 class="tw-mb-8 tw-mt-6 tw-text-3xl tw-font-bold">{{ Math.ceil(parseFloat(currentServingSize)) }}g</h3>
          <div class="tw-text-body-md">
            Feeds <span class="tw-font-bold">{{ message.amount }}</span> of {{ dogName }}’s daily nutrients
          </div>
        </div>
        <div>
          <StepButton
            data-testid="incremental-button"
            :action-type="'increment'"
            @update-value="onIncreaseServingSize"
          />
        </div>
      </div>
      <div class="tw-mx-16 tw-flex tw-flex-row tw-justify-center">
        <div
          v-if="currentServingSize !== props.recommendedServingSize"
          class="tw-text-body-sm tw-my-4 tw-max-w-[250px] tw-rounded-3xl tw-bg-surface-brand-secondary tw-p-4 tw-text-center sm:tw-mx-4 md:tw-mx-10"
        >
          {{ message.suggestion }}
        </div>
        <div
          v-if="currentServingSize === props.recommendedServingSize"
          class="tw-text-body-sm tw-mx-4 tw-my-4 tw-inline-flex tw-flex-row tw-items-center tw-justify-between tw-rounded tw-bg-btn-primary tw-p-2 tw-text-center sm:tw-mx-4 md:tw-mx-12"
        >
          <img src="@/assets/icons/bling-white.svg" class="tw-h-[11px] tw-w-[11px] tw-cursor-pointer" alt="bling" />
          <span class="tw-mx-1 tw-text-white">Recommended</span>
          <img src="@/assets/icons/info-white.svg" class="tw-h-[13px] tw-w-[13px] tw-cursor-pointer" alt="info" />
        </div>
      </div>

      <PawDivider />

      <div class="tw-w-full tw-cursor-pointer tw-px-4">
        <div
          class="tw-border-secondary -tw-mx-4 tw-mb-32 tw-flex tw-flex-row tw-flex-nowrap tw-items-center tw-rounded-3xl tw-border-2 tw-bg-surface-light tw-p-4"
        >
          <img src="@/assets/icons/personal-bullet.svg" alt="personal-bullet" class="tw-w-[32px]" />
          <div class="tw-text-body-sm tw-mx-3 tw-flex-auto">
            Something’s changed?
            <span class="tw-font-bold tw-text-content-accent">Update profile & get a new recommendation</span>
          </div>
          <img src="@/assets/icons/chevron-right.svg" alt="chevron-right.svg" class="tw-h-[16px]" />
        </div>
      </div>
    </div>

    <template #postView>
      <StickyBottomGeneral
        :loading="loading"
        :confirm-text="'Review changes'"
        :confirm-disabled="initialIndex === currentIndex || loading"
        @cancel="emits('cancel')"
        @confirm="emits('reviewChanges', currentServingSize)"
      />
    </template>
  </ViewLayout>
</template>
