import { initContract } from '@ts-rest/core'
import { ErrorResponseSchema } from './general'
import { Subscription } from '@repo/api-types/src/subscription/types'
import {
  FeedingPlanServingSize,
  FeedingPlanType,
  UpdateSubscriptionBodyExtraSchema,
  UpdateSubscriptionBodyFeedingPlanSkuSchema,
} from '@lyka-pet-food/contracts/services/subscription'
import { z } from 'zod'
import { FeesSchema } from '@lyka-pet-food/contracts/services/pricing'
import { OrderResponseDto } from '@lyka-pet-food/contracts/services/order'

const c = initContract()

export const subscriptionContract = c.router(
  {
    getSubscriptions: {
      method: 'GET',
      path: '/subscriptions',
      responses: {
        200: c.type<{ subscriptions: Subscription[] }>(),
      },
    },
    updateSubscriptionExtras: {
      method: 'POST',
      path: '/subscriptions/:subscriptionId/update-extras',
      pathParams: z.object({
        subscriptionId: z.string(),
      }),
      body: z.array(UpdateSubscriptionBodyExtraSchema),
      responses: {
        200: c.type<{ subscription: Subscription }>(),
      },
    },
    updateSubscriptionRecipes: {
      method: 'POST',
      path: '/subscriptions/:subscriptionId/:feedingPlanId/update-recipes',
      pathParams: z.object({
        subscriptionId: z.string(),
        feedingPlanId: z.string(),
      }),
      body: z.array(UpdateSubscriptionBodyFeedingPlanSkuSchema),
      responses: {
        200: c.type<{ subscription: Subscription }>(),
      },
    },
    updateSubscriptionServingSize: {
      method: 'POST',
      path: '/subscriptions/update-serving-size',
      body: z.object({
        subscriptionId: z.string(),
        feedingPlanId: z.string(),
        servingSize: z.nativeEnum(FeedingPlanServingSize),
      }),
      responses: {
        200: c.type<{ subscription: Subscription }>(),
      },
    },
    updateSubscriptionDeliveryDate: {
      method: 'POST',
      path: '/subscriptions/update-order-delivery-date',
      body: z.object({
        orderId: z.string(),
        deliveryDate: z.string(),
      }),
      responses: {
        200: c.type<{ orders: OrderResponseDto[] }>(),
      },
    },
    updateSubscriptionFeedingPlanType: {
      method: 'POST',
      path: '/subscriptions/update-subscription-feeding-plan-type',
      body: z.object({
        subscriptionId: z.string(),
        feedingPlanType: z.nativeEnum(FeedingPlanType),
      }),
      responses: {
        200: c.type<{ subscription: Subscription }>(),
      },
    },
  },
  {
    commonResponses: {
      400: ErrorResponseSchema,
      401: ErrorResponseSchema,
      404: ErrorResponseSchema,
      500: ErrorResponseSchema,
    },
  },
)
