<script setup lang="ts">
import ViewLayout from '@/components/ViewLayout.vue'
import { withDefaults, ref, onBeforeMount, computed } from 'vue'
import { PawHeading, PawDivider } from '@lyka-pet-food/lyka-common'
import EditProductItem from '@/components/EditProductItem.vue'
import StickyBottomEditProduct from '@/components/stickyNav/StickyBottomEditProduct.vue'
import { dateToLocalString } from '@/utils/dateToLocalString'
import { EnrichedOrder, OrderLineItem } from '@repo/api-types/src/order/types'

const props = withDefaults(
  defineProps<{
    lineItems?: OrderLineItem[]
    order?: EnrichedOrder
  }>(),
  {},
)

const emits = defineEmits<{
  (e: 'cancelEditProducts'): void
  (e: 'confirmEditProducts', updatedProducts: OrderLineItem[], order?: EnrichedOrder): void
  (e: 'addProducts', lineItems?: OrderLineItem[], order?: EnrichedOrder): void
}>()

const editProducts = ref<Array<OrderLineItem>>([])

const onUpdateQuantity = (index: number, quantity: number): void => {
  editProducts.value[index].quantity = quantity
}

const totalMealsQuantity = computed(() => {
  return editProducts.value?.reduce((a, b) => a + b.quantity, 0) || 0
})

const totalPrice = computed(() => {
  return editProducts.value?.reduce((a, b) => a + b.price * b.quantity, 0)
})

const updatedProducts = computed(() => {
  return editProducts.value
})

onBeforeMount(() => {
  editProducts.value =
    props.lineItems?.map((item) => {
      return { ...item }
    }) || []
})
</script>

<template>
  <ViewLayout>
    <div class="tw-max-w-[1140px] tw-space-y-8">
      <div class="tw-flex tw-flex-row tw-items-start tw-self-start">
        <img
          src="@/assets/icons/chevron-left.svg"
          class="tw-cursor-pointer tw-pr-4 md:tw-pt-1"
          @click="emits('cancelEditProducts')"
        />
        <div class="tw-space-y-1">
          <PawHeading size="3xl">Edit extras for {{ dateToLocalString(props.order?.deliveryDate) }}</PawHeading>
          <div class="tw-text-body-md tw-text-content-accent">View all your extras</div>
        </div>
      </div>

      <div class="tw-w-full">
        <div
          class="-tw-mx-4 tw-mb-52 tw-rounded-3xl tw-p-4 md:tw-mx-0 md:tw-grid md:tw-w-full md:tw-grid-cols-3 md:tw-gap-8 xl:tw-grid-cols-4 min-[1440px]:tw-p-8"
        >
          <div v-for="(product, index) in editProducts" :key="`meal-item-${index}`">
            <EditProductItem :line-item="product" @update-quantity="onUpdateQuantity(index, $event)" />
            <PawDivider class="tw-my-4 md:tw-hidden" />
          </div>

          <div
            class="tw-border-primary tw-flex tw-cursor-pointer tw-items-center tw-space-x-4 tw-rounded-xl tw-border tw-border-dashed tw-p-2 tw-text-center md:tw-h-[164px] md:tw-w-[229px] md:tw-flex-col md:tw-justify-center md:tw-space-x-2 md:tw-space-y-2"
            @click="emits('addProducts', props.lineItems, props.order)"
          >
            <div
              class="tw-flex tw-min-h-16 tw-min-w-16 tw-items-center tw-justify-center tw-rounded-lg tw-bg-surface-brand-secondary md:tw-min-h-[52px] md:tw-min-w-[52px]"
            >
              <img src="@/assets/images/deliveries/plus-icon.svg" />
            </div>
            <div class="tw-text-body-md md:tw-text-body-lg">Add extras to this delivery</div>
          </div>

          <div class="tw-text-body-lg tw-mt-12 tw-text-center md:tw-hidden">
            Total: <span class="tw-font-bold">${{ totalPrice.toFixed(2) }}</span>
          </div>
        </div>
      </div>
    </div>

    <template #postView>
      <StickyBottomEditProduct
        :total-quantity="totalMealsQuantity"
        :total-price="totalPrice"
        @cancel="emits('cancelEditProducts')"
        @confirm="emits('confirmEditProducts', updatedProducts, props.order)"
      />
    </template>
  </ViewLayout>
</template>
