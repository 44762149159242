<script setup lang="ts">
import { ref, computed } from 'vue'
import { useAnimalsStore } from '@/stores/animals'
import Deliveries from '@/views/Deliveries.vue'
import { SlotAvailability } from '@repo/api-types'
import { ProductType } from '@/types/products'
import { IonPage } from '@ionic/vue'
import { EditProductMeal } from '@/views/EditMeals.vue'
import { type ProductToEdit } from '@/views/AddProducts.vue'
import { useDeprecatedOrders } from '@/composables/useDeprecatedOrders'
import { useAppStateStore } from '@/stores/appState'
import { AvailableSlotName, AvailableDates } from '@/types/delivery'
import { PawDialogBox } from '@lyka-pet-food/lyka-common'
import AddProducts from '@/views/AddProducts.vue'
import EditProducts from '@/views/EditProducts.vue'
import EditMeals from '@/views/EditMeals.vue'
import SelectDeliveryDateModal from '@/components/selectDeliveryDateModal.vue'
import { loadingWrapper } from '@/utils/function'
import { EnrichedOrder, OrderFeedingPlan, OrderLineItem } from '@repo/api-types/src/order/types'
import { useProductStore } from '@/stores/products'
import { useSubscriptionStore } from '@/stores/subscriptions'
import { useSubscriptions } from '@/composables/useSubscriptions'
import { UpdateSubscriptionBodyFeedingPlanSku } from '@lyka-pet-food/contracts/services/subscription'
import { useOrderStore } from '@/stores/orders'
import cloneDeep from 'lodash/cloneDeep'

interface EditDeliveryDate {
  date: string
  slot: string
  order: EnrichedOrder
}

const editMealsToggle = ref(false)
const editDogMeals = ref<OrderFeedingPlan>()
const editMealsOrder = ref<EnrichedOrder>()

const editDeliveryDateToggle = ref(false)
const editDeliveryDate = ref<EditDeliveryDate>()
const editDeliveryAvailableDates = ref<AvailableDates[]>([])

const addProductsToggle = ref(false)
const addDogProducts = ref<OrderLineItem[]>()

const updateProductsOrder = ref<EnrichedOrder>()

const editProductsToggle = ref(false)
const editDogProducts = ref<OrderLineItem[]>()

const editCancelWarning = ref(false)

const orderStore = useOrderStore()
const productStore = useProductStore()
const subscriptionStore = useSubscriptionStore()

const animalsStore = useAnimalsStore()
const appStateStore = useAppStateStore()

const loading = ref(false)
const setLoading = (isLoading: boolean) => (loading.value = isLoading)

const onCancelEditMeals = () => {
  toggleEditCancelWarningDialog()
}

const onCancelAddProducts = () => {
  addProductsToggle.value = false
}

const onCancelEditProducts = () => {
  editProductsToggle.value = false
}

const onConfirmAddProducts = async (updatedProducts: Array<ProductToEdit>, order?: EnrichedOrder) => {
  if (!order) {
    return
  }

  const productsToAdd = updatedProducts
    .filter((x) => x.quantity !== 0)
    .map((x) => {
      return {
        productVariantId: x.variants[0].id,
        type: x.type,
        startOrderIndex: order.orderNumber,
        nthOrder: x.subscriptionFrequency,
        quantity: x.quantity,
        price: x.price,
      }
    })

  appStateStore.updateLoadingState(true)

  const productEditResult = await useSubscriptions().updateSubscriptionExtra(
    order.subscriptionId ?? '',
    productsToAdd as any,
  )

  if (productEditResult?.error || productEditResult?.error) {
    alert('Failed to update products')
    return
  }

  await orderStore.load()
  await subscriptionStore.load()

  appStateStore.updateLoadingState(false)

  appStateStore.updateSuccessState(true)
  setTimeout(() => {
    appStateStore.updateSuccessState(false)
    addProductsToggle.value = false
  }, 1000)
}

const onConfirmEditProducts = async (updatedProducts: OrderLineItem[], order?: EnrichedOrder) => {
  const userExtraSubscription = cloneDeep(subscriptionStore.userActiveSubscription?.extras)

  if (!userExtraSubscription || !order) {
    return
  }

  userExtraSubscription?.map((x) => {
    const updatedExtra = updatedProducts.find((y) => y.productId === x.productVariantId)
    if (updatedExtra) {
      x.quantity = updatedExtra.quantity
    }
  })

  appStateStore.updateLoadingState(true)

  const productEditResult = await useSubscriptions().updateSubscriptionExtra(
    order.subscriptionId ?? '',
    userExtraSubscription as any,
  )

  if (productEditResult?.error || productEditResult?.error) {
    alert('Failed to update products')
    return
  }

  await orderStore.load()
  await subscriptionStore.load()

  appStateStore.updateLoadingState(false)

  appStateStore.updateSuccessState(true)
  setTimeout(() => {
    appStateStore.updateSuccessState(false)
    editProductsToggle.value = false
  }, 1000)

  // TODO: handle one-off products
}

const onConfirmEditMeals = async (updatedProducts: Array<EditProductMeal>, order?: EnrichedOrder) => {
  if (!order) {
    return
  }

  const feedingPlan = order.feedingPlans[0]

  const subscriptionFeedingPlan = subscriptionStore.userActiveSubscription?.feedingPlans?.find(
    (x) => x.animalId === feedingPlan.animalId,
  )

  if (!subscriptionFeedingPlan) {
    return
  }

  const dailyServingSizeMatch = subscriptionFeedingPlan.currentDailyServingSizeInGrams.match(/[1-9]\d*/)

  if (!dailyServingSizeMatch) {
    return
  }

  const dailyServingSize = dailyServingSizeMatch[0]

  let mealsToUpdate = [] as UpdateSubscriptionBodyFeedingPlanSku[]

  updatedProducts.forEach((product) => {
    // 1. Find product variant
    const productVariant = product.variants.find((x) => x.sku.includes(dailyServingSize))

    if (productVariant) {
      // 2. Push to mealsToUpdate
      mealsToUpdate.push({
        productVariantId: productVariant.id,
        ratio: product.quantity,
        price: productVariant.basePrice ?? 0,
      })
    }
  })

  appStateStore.updateLoadingState(true)

  const productEditResult = await useSubscriptions().updateSubscriptionMeals(
    order.subscriptionId ?? '',
    subscriptionFeedingPlan.id,
    mealsToUpdate,
  )
  if (productEditResult?.error || productEditResult?.error) {
    alert('Failed to update products')
    return
  }

  await orderStore.load()
  await subscriptionStore.load()

  appStateStore.updateLoadingState(false)

  appStateStore.updateSuccessState(true)
  setTimeout(() => {
    appStateStore.updateSuccessState(false)
    editMealsToggle.value = false
  }, 1000)
}

const onEditFutureOrderMeals = (lineItems: OrderFeedingPlan, order: EnrichedOrder) => {
  editDogMeals.value = lineItems
  editMealsOrder.value = order
  editMealsToggle.value = true
}

const onAddFutureOrderProducts = (lineItems: OrderLineItem[], order: EnrichedOrder) => {
  addDogProducts.value = lineItems
  updateProductsOrder.value = order
  addProductsToggle.value = true
}

const onAddFutureOrderProductsCloseEditProducts = (lineItems?: OrderLineItem[], order?: EnrichedOrder) => {
  addDogProducts.value = lineItems
  updateProductsOrder.value = order
  editProductsToggle.value = false
  addProductsToggle.value = true
}

const onEditFutureOrderProducts = (lineItems: OrderLineItem[], order: EnrichedOrder) => {
  editDogProducts.value = lineItems
  updateProductsOrder.value = order
  editProductsToggle.value = true
}

const onEditFutureOrderDeliveryDate = (date: string, slot: string, order: EnrichedOrder) =>
  loadingWrapper(async () => {
    if (!order.address.postcode || !order.address.city) {
      throw new Error('Order is missing postcode or city')
    }
    editDeliveryDateToggle.value = true
    editDeliveryDate.value = { date, slot, order }

    const { error, deliverySchedule } = await useDeprecatedOrders().getAvailableDeliveries(
      order.address.postcode,
      order.address.city,
    )

    if (error || !deliverySchedule) {
      throw new Error('Could not get delivery schedule')
    }

    const deliveryScheduleMapped = deliverySchedule.map((x) => {
      if (x.slotAvailability === SlotAvailability.BothAvailable)
        return {
          date: x.deliveryDate,
          timeSlots: [{ slot: AvailableSlotName.AM }, { slot: AvailableSlotName.PM }],
        }

      if (x.slotAvailability === SlotAvailability.AmAvailable)
        return {
          date: x.deliveryDate,
          timeSlots: [{ slot: AvailableSlotName.AM }, { slot: AvailableSlotName.PM, disabled: true }],
        }

      if (x.slotAvailability === SlotAvailability.PmAvailable)
        return {
          date: x.deliveryDate,
          timeSlots: [{ slot: AvailableSlotName.AM, disabled: true }, { slot: AvailableSlotName.PM }],
        }

      return null
    })

    editDeliveryAvailableDates.value = deliveryScheduleMapped
      .filter((x) => x !== null)
      .sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf())

    appStateStore.updateFooterState(false)
  }, setLoading)

const onConfirmUpdateFutureOrderDelivery = (deliveryDate: string, deliverySlot: string) =>
  loadingWrapper(async () => {
    const subscriptionId = subscriptionStore.userActiveSubscription?.id
    const order = editDeliveryDate.value?.order

    if (!subscriptionId || !order) return

    const deliveryPreference = deliverySlot === 'AM' ? 'am' : 'pm'

    const { error, orders } = await useSubscriptions().updateSubscriptionDeliveryDate(order.id, deliveryDate)
    if (error) {
      return
    } else {
      await orderStore.load()
      await subscriptionStore.load()
      editDeliveryDateToggle.value = false
      appStateStore.updateFooterState(true)
    }
  }, setLoading)

const onCancelEditFutureOrderDelivery = (): void => {
  editDeliveryDateToggle.value = false
  appStateStore.updateFooterState(true)
}

const productMeals = computed(() => {
  return productStore.allProducts?.filter((x) => x?.type === 'meal')
})

const productExtras = computed(() => {
  return productStore.allProducts?.filter((x) => x?.type === ProductType.TREAT)
})

const editMealsDogName = computed(
  () =>
    editMealsOrder.value?.feedingPlans[0].animalId &&
    (animalsStore.getDogById(editMealsOrder.value?.feedingPlans[0].animalId)?.name ?? ''),
)

const toggleEditCancelWarningDialog = () => {
  editCancelWarning.value = !editCancelWarning.value
}

const onClickConfirmCancelWarningDialog = () => {
  toggleEditCancelWarningDialog()
  editMealsToggle.value = false
}
</script>

<template>
  <ion-page>
    <div v-if="editCancelWarning" class="tw-z-10">
      <PawDialogBox
        :open="editCancelWarning"
        has-cancel-btn
        outlined-button-text="Yes"
        class="tw-h-screen"
        @click-close="toggleEditCancelWarningDialog"
        @click-outlined-btn="onClickConfirmCancelWarningDialog"
      >
        <p>Discard changes?</p>
      </PawDialogBox>
    </div>

    <Deliveries
      v-show="!editMealsToggle || !addProductsToggle"
      :loading="orderStore.loading"
      @edit-future-order-meals="onEditFutureOrderMeals"
      @add-future-order-products="onAddFutureOrderProducts"
      @edit-future-order-products="onEditFutureOrderProducts"
      @edit-future-order-delivery-date="onEditFutureOrderDeliveryDate"
      key="deliveries"
    />

    <TransitionGroup name="slide-in">
      <EditMeals
        v-if="editMealsToggle"
        :order-feeding-plan="editDogMeals"
        :product-meals="productMeals"
        :dog-name="editMealsDogName"
        :order="editMealsOrder"
        :total-pouches="editDogMeals?.skus?.reduce((a, b) => a + b.quantity, 0) || 0"
        @cancel-edit-meal="onCancelEditMeals"
        @confirm-edit-meal="onConfirmEditMeals"
        key="edit-meals"
        class="tw-absolute tw-top-0"
      />
    </TransitionGroup>

    <TransitionGroup name="slide-in">
      <AddProducts
        v-if="addProductsToggle"
        :line-items="addDogProducts"
        :product-meals="productExtras"
        :dog-name="editMealsDogName"
        :order="updateProductsOrder"
        @cancel-add-products="onCancelAddProducts"
        @confirm-add-products="onConfirmAddProducts"
        key="add-products"
        class="tw-absolute tw-top-0"
      />
    </TransitionGroup>

    <TransitionGroup name="slide-in">
      <EditProducts
        v-if="editProductsToggle"
        :line-items="editDogProducts"
        :dog-name="editMealsDogName"
        :order="updateProductsOrder"
        @cancel-edit-products="onCancelEditProducts"
        @confirm-edit-products="onConfirmEditProducts"
        @add-products="onAddFutureOrderProductsCloseEditProducts"
        key="edit-products"
        class="tw-absolute tw-top-0"
      />
    </TransitionGroup>

    <SelectDeliveryDateModal
      v-if="editDeliveryDateToggle"
      :loading="loading"
      :delivery-date="editDeliveryDate?.date"
      :delivery-slot="editDeliveryDate?.slot"
      :available-dates="editDeliveryAvailableDates"
      @cancel="onCancelEditFutureOrderDelivery"
      @update="onConfirmUpdateFutureOrderDelivery"
    />
  </ion-page>
</template>

<style scoped>
.slide-in-leave-active,
.slide-in-enter-active {
  @apply tw-translate-x-0 max-md:tw-transform max-md:tw-duration-500;
}

.slide-in-enter-from,
.slide-in-leave-to {
  @apply max-md:tw-translate-x-full max-md:tw-transform;
}
</style>
