import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import type { EnrichedOrder } from '@repo/api-types/src/order/types'
import { useOrders } from '@/composables/useOrders'

export const useOrderStore = defineStore('orders', () => {
  const pastOrders = ref<EnrichedOrder[]>([])
  const currentOrders = ref<EnrichedOrder[]>([])
  const futureOrders = ref<EnrichedOrder[]>([])
  const orders = ref<EnrichedOrder[]>([])
  const loading = ref(false)

  const load = async () => {
    loading.value = true
    const { past, current, future, error } = await useOrders().getOrders()
    // TODO: Move to error  handling
    if (error) {
      console.error(error)
      loading.value = false
      return
    }
    pastOrders.value = past || []
    currentOrders.value = current || []
    futureOrders.value = future || []
    orders.value = [...pastOrders.value, ...currentOrders.value, ...futureOrders.value]
    loading.value = false
  }

  const recentOrders = computed(() => {
    return [...pastOrders.value, ...currentOrders.value]
  })

  return {
    loading,
    recentOrders,
    pastOrders,
    currentOrders,
    futureOrders,
    orders,
    load,
  }
})
