import { initContract } from '@ts-rest/core'
import { authContract } from './auth'
import { entitiesContract } from './entities'
import { fulfillmentContract } from './fulfillment'
import { ecommerceContract } from './ecommerce'
import { courierContract } from './courier'
import { recommendationContract } from './recommendation'
import { productContract } from './product'
import { subscriptionContract } from './subscription'
import { orderContract } from './order'

const c = initContract()

export const contract = c.router({
  auth: authContract,
  entities: entitiesContract,
  fulfillment: fulfillmentContract,
  ecommerce: ecommerceContract,
  courier: courierContract,
  recommendation: recommendationContract,
  product: productContract,
  subscription: subscriptionContract,
  order: orderContract,
})

export {
  authContract,
  entitiesContract,
  fulfillmentContract,
  ecommerceContract,
  courierContract,
  recommendationContract,
  productContract,
  subscriptionContract,
  orderContract,
}
