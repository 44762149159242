import { useClient } from '@/composables/useClient'
import type { EnrichedOrder } from '@repo/api-types/src/order/types'

export const useOrders = () => {
  const getOrders = async (): Promise<{
    error?: string | null
    past?: EnrichedOrder[]
    current?: EnrichedOrder[]
    future?: EnrichedOrder[]
  }> => {
    const client = await useClient()
    const { status, body } = await client.order.getOrders()
    if (status === 200) {
      return { past: body.pastOrders, current: body.currentOrders, future: body.futureOrders }
    }
    return { error: (body as unknown as { error?: string }).error || 'Unspecified server error' }
  }

  return {
    getOrders,
  }
}
