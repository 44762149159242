<script setup lang="ts">
import ViewLayout from '@/components/ViewLayout.vue'
import { withDefaults, ref, onBeforeMount, computed } from 'vue'
import { PawHeading, PawDivider } from '@lyka-pet-food/lyka-common'
import MealsItem from '@/components/MealsItem.vue'
import StickyBottomNav from '@/components/stickyNav/StickyBottomNav.vue'
import { EnrichedOrder, OrderFeedingPlan } from '@repo/api-types/src/order/types'
import { SubscriptionFeedingPlan } from '@repo/api-types/src/subscription/types'
import { Product } from '@repo/api-types/src/product/types'
import { useProductStore } from '@/stores/products'

export type EditProductMeal = Product & {
  quantity: number
}

const props = withDefaults(
  defineProps<{
    dogName?: string
    orderFeedingPlan?: OrderFeedingPlan
    subscriptionFeedingPlan?: SubscriptionFeedingPlan
    productMeals?: Array<Product>
    totalPouches?: number
    order?: EnrichedOrder
  }>(),
  {},
)

const emits = defineEmits<{
  (e: 'cancelEditMeal'): void
  (e: 'confirmEditMeal', updatedProducts: Array<EditProductMeal>, order?: EnrichedOrder): void
}>()

const editMeals = ref<Array<EditProductMeal>>([])

const productStore = useProductStore()

const onUpdateQuantity = (index: number, quantity: number): void => {
  editMeals.value[index].quantity = quantity
}

const selectedMealsQuantity = computed(() => {
  return editMeals.value.reduce((a, b) => a + b.quantity, 0)
})

const updatedProducts = computed(() => {
  return editMeals.value
})

onBeforeMount(() => {
  editMeals.value =
    props.productMeals?.map((product) => {
      return {
        ...product,
        quantity:
          props.orderFeedingPlan?.skus?.find(
            (lineItem) => productStore.getProductByProductVariantId(lineItem.productId ?? '')?.id === product?.id,
          )?.quantity ||
          props.subscriptionFeedingPlan?.skus?.find(
            (x) => productStore.getProductByProductVariantId(x.productVariantId ?? '')?.id === product?.id,
          )?.ratio ||
          0,
      }
    }) || []
})
</script>

<template>
  <ViewLayout>
    <div class="tw-max-w-[1140px] tw-space-y-8">
      <div class="tw-flex tw-flex-row tw-items-start tw-self-start">
        <img
          src="@/assets/icons/chevron-left.svg"
          class="tw-cursor-pointer tw-pr-4 md:tw-pt-1"
          @click="emits('cancelEditMeal')"
        />
        <div class="tw-space-y-1">
          <PawHeading size="3xl">Edit {{ dogName }}'s meal plan</PawHeading>
          <div class="tw-text-body-md">Changes will be applied to all deliveries</div>
        </div>
      </div>

      <div class="tw-w-full">
        <div
          class="-tw-mx-4 tw-mb-52 tw-rounded-3xl tw-bg-surface-light tw-p-4 md:tw-mx-0 md:tw-grid md:tw-w-full md:tw-grid-cols-3 md:tw-gap-8 xl:tw-grid-cols-4 min-[1440px]:tw-p-8"
        >
          <div v-for="(product, index) in editMeals" :key="`meal-item-${index}`">
            <MealsItem
              :title="product.name"
              :image-path="product.images.filter((x) => x.imageType === 'bowl_icon')[0]?.imageUrl"
              :quantity="product.quantity"
              @update-quantity="onUpdateQuantity(index, $event)"
            />
            <PawDivider v-if="index !== editMeals.length - 1" class="tw-my-4 md:tw-hidden" />
          </div>
        </div>
      </div>
    </div>

    <template #postView>
      <StickyBottomNav
        :selected-quantity="selectedMealsQuantity"
        :total-quantity="totalPouches as number"
        @cancel="emits('cancelEditMeal')"
        @confirm="emits('confirmEditMeal', updatedProducts, order)"
      />
    </template>
  </ViewLayout>
</template>
