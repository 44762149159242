<script setup lang="ts">
import { useAnimalsStore } from '@/stores/animals'
import { PawButtonOutlined, PawHeading } from '@lyka-pet-food/lyka-common'
import { RetrieveDogReturnDto } from '@repo/api-types/src/generated/entities'
import { computed } from 'vue'

const props = defineProps<{
  dog: RetrieveDogReturnDto
}>()

const emit = defineEmits<{
  (e: 'editProfile', pet: RetrieveDogReturnDto): void
}>()

const animalsStore = useAnimalsStore()

const foodAllergiesNames = computed(() => {
  return props.dog?.foodAllergies?.map((id) => animalsStore.getAllergenBySlug(id)?.name)
})

const healthIssuesNames = computed(() => {
  return props.dog?.healthIssues?.map((id) => animalsStore.getHealthIssueBySlug(id)?.name)
})
</script>

<template>
  <PawHeading size="2xl" type="subheading">{{ dog?.name }}'s Profile</PawHeading>

  <div>Keep this updated to ensure {{ dog?.name }} gets the right amount of Lyka for his needs.</div>

  <div class="tw-bg-white tw-rounded-xl tw-flex tw-flex-col tw-custom-shadow tw-w-full">
    <div class="tw-border-b tw-p-5 tw-py-6 tw-flex tw-justify-center md:tw-justify-start">
      <PawButtonOutlined class="tw-border-solid tw-w-full md:tw-w-[330px]" @click="emit('editProfile', dog)">
        Update profile
      </PawButtonOutlined>
    </div>
    <div class="tw-p-5 tw-flex tw-flex-col tw-gap-4 md:tw-flex-row md:tw-gap-5 tw-text-body-md">
      <div class="tw-flex tw-basis-1/3 tw-border-b md:tw-border-b-0 md:tw-border-r tw-pb-5 md:tw-pb-0">
        <div class="tw-space-y-1">
          <div class="tw-text-body-sm">Weight & Activity</div>
          <div>
            {{ dog?.weight }}kg, <span class="tw-capitalize">{{ dog?.bodyType }}</span
            >, <span class="tw-capitalize">{{ dog?.activityLevel?.replace('_', ' ') }}</span>
          </div>
        </div>
      </div>
      <div class="tw-flex tw-basis-1/3 tw-border-b md:tw-border-b-0 md:tw-border-r tw-pb-5 md:tw-pb-0">
        <div class="tw-space-y-1">
          <div class="tw-text-body-sm">Health</div>
          <div v-if="healthIssuesNames && healthIssuesNames.length > 0">{{ healthIssuesNames.join(', ') }}</div>
          <div v-else>No Health Issues</div>
        </div>
      </div>
      <div class="tw-flex tw-basis-1/3">
        <div class="tw-space-y-1">
          <div class="tw-text-body-sm">Intolerances or allergies</div>
          <div v-if="foodAllergiesNames && foodAllergiesNames.length > 0">
            {{ foodAllergiesNames.join(', ') }}
          </div>
          <div v-else>No intolerances or allergies</div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="postcss">
.tw-custom-shadow {
  @apply tw-shadow-[2px_6px_20px_0_rgba(0,0,0,0.08)];
}
</style>
