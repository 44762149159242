import { initContract } from '@ts-rest/core'
import { z } from 'zod'

import { Order, BillingCycleOrder, Product, ProductVariant, SubscriptionContract, Subscription } from '@repo/api-types'
import type {
  ConsolidateUpdateSubscriptionContractPayload,
  UpdateSubscriptionContractBillingCyclePayload,
} from '@repo/api-types/src/generated/ecommerce'
import {
  UpdateFeedingPlanBodySchema,
  UpdateFeedingPlanBodySchemaValues,
  UpdateSubscriptionPlanBodySchema,
} from '@repo/api-types/src/ecommerce/schemas'

const c = initContract()

export const ecommerceContract = c.router(
  {
    getProducts: {
      method: 'GET',
      path: '/deprecate-products',
      responses: {
        200: c.type<{ products: Product[]; productVariants: ProductVariant[] }>(),
        500: c.type<{ error: string }>(),
      },
    },
    getSubscriptions: {
      method: 'GET',
      path: '/deprecate-subscriptions',
      responses: {
        200: c.type<{ subContracts: SubscriptionContract[]; subscriptions: Subscription[] }>(),
        500: c.type<{ error: string }>(),
      },
    },
    getOrders: {
      method: 'GET',
      path: '/deprecate-orders',
      responses: {
        200: c.type<{
          past: Order[]
          current: Order
          future: BillingCycleOrder[]
        }>(),
        500: c.type<{ error: string }>(),
      },
    },
    updateSubscription: {
      method: 'PUT',
      path: '/deprecate-subscriptions/:id/update',
      pathParams: z.object({
        id: z.string(),
      }),
      body: UpdateSubscriptionPlanBodySchema,
      responses: {
        200: c.type<{ subscription: Subscription }>(),
        500: c.type<{ error: string }>(),
        404: c.type<{ error: string }>(),
        401: c.type<{ error: string }>(),
      },
    },
    updateSubscriptionContract: {
      method: 'PATCH',
      path: '/deprecate-subscription-contracts/:id/update',
      pathParams: z.object({
        id: z.string(),
      }),
      responses: {
        200: c.type<{ subContract: SubscriptionContract }>(),
        500: c.type<{ error: string }>(),
        404: c.type<{ error: string }>(),
        401: c.type<{ error: string }>(),
      },
      body: z.custom<ConsolidateUpdateSubscriptionContractPayload>(),
    },
    updateSubscriptionContractBillingCycle: {
      method: 'PATCH',
      path: '/deprecate-subscription-contracts/:id/billing-cycles/:cycleIndex/update',
      pathParams: z.object({
        cycleIndex: z.coerce.number(),
        id: z.string(),
      }),
      responses: {
        200: c.type<{ billingCycle: BillingCycleOrder }>(),
        500: c.type<{ error: string }>(),
        404: c.type<{ error: string }>(),
        401: c.type<{ error: string }>(),
      },
      body: z.custom<UpdateSubscriptionContractBillingCyclePayload>(),
    },
    updateFeedingPlan: {
      method: 'PUT',
      path: '/deprecate-plans/:id/:contractId/update',
      pathParams: z.object({
        id: z.string(),
        contractId: z.string(),
      }),
      body: UpdateFeedingPlanBodySchema,
      responses: {
        200: c.type<{ subscription: Subscription; subContract: SubscriptionContract }>(),
        500: c.type<{ error: string }>(),
        404: c.type<{ error: string }>(),
        401: c.type<{ error: string }>(),
      },
    },
    // DEPRECATED
    postSubscriptionContractBillingCycleUpdateMeals: {
      method: 'POST',
      path: '/deprecate-subscription-contracts/:id/billing-cycles/:cycleIndex/update-meals',
      responses: {
        200: c.type<{
          removed: string[]
          added: string[]
          updated: string[]
        }>(),
        500: c.type<{ error: string }>(),
        404: c.type<{ error: string }>(),
        401: c.type<{ error: string }>(),
      },
      body: z.object({
        lineItems: z
          .object({
            productVariantId: z.string(),
            quantity: z.number(),
          })
          .array(),
      }),
    },
    postSubscriptionContractBillingCycleUpdateDeliveryDate: {
      method: 'POST',
      path: '/deprecate-subscription-contracts/:id/billing-cycles/:cycleIndex/update-delivery-date',
      pathParams: z.object({
        cycleIndex: z.coerce.number(),
        id: z.string(),
      }),
      responses: {
        200: c.type<{
          billingDate: string
          deliveryDate: string
          subscriptionContractId: string
        }>(),
        500: c.type<{ error: string }>(),
        404: c.type<{ error: string }>(),
        401: c.type<{ error: string }>(),
      },
      body: z.object({
        deliveryDate: z.string().date(),
        deliveryTimePreference: z.enum(['am', 'pm']),
      }),
    },
  },
  {
    pathPrefix: '/deprecated',
  },
)
