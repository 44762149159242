<script setup lang="ts">
import { computed, ref } from 'vue'
import { IonPage } from '@ionic/vue'
import ViewLayout from '@/components/ViewLayout.vue'
import CalendarTab from '@/components/calendar/CalendarTab.vue'
import { PawHeading } from '@lyka-pet-food/lyka-common'
import { EnrichedOrder, OrderFeedingPlan, OrderLineItem } from '@repo/api-types/src/order/types'
import DeliverySlide from '@/components/DeliverySlide.vue'
import { useOrderStore } from '@/stores/orders'

const props = defineProps<{
  loading: boolean
}>()

const emits = defineEmits<{
  (e: 'editFutureOrderMeals', lineItems: OrderFeedingPlan, order: EnrichedOrder): void
  (e: 'addFutureOrderProducts', lineItems: OrderLineItem[], order: EnrichedOrder): void
  (e: 'editFutureOrderProducts', lineItems: OrderLineItem[], order: EnrichedOrder): void
  (e: 'editFutureOrderDeliveryDate', date: string, slot: string, order: EnrichedOrder): void
}>()

const activeIndex = ref(1)
const slideFadeAnimationName = ref('')

const orderStore = useOrderStore()

const deliveryDetails = computed(() => {
  return orderStore.orders.map((x) => {
    return {
      status: x.displayType,
      date: x.deliveryDate,
      slot: x.expectedDeliverySlotId,
    }
  })
})

const scrollToItem = (index: number) => {
  if (index > activeIndex.value) {
    slideFadeAnimationName.value = 'slide-fade-right'
  } else {
    slideFadeAnimationName.value = 'slide-fade-left'
  }
  activeIndex.value = index
}

const calendarBgSvgTransition = computed(() => {
  if (activeIndex.value === 0) {
    return 'tw-translate-x-[366px]'
  } else if (activeIndex.value === 1) {
    return 'tw-translate-x-[200px]'
  } else if (activeIndex.value === 2) {
    return 'tw-translate-x-[120px]'
  } else if (activeIndex.value === 3) {
    return 'tw-translate-x-[40px]'
  } else {
    return ''
  }
})

const onEditFutureOrderMeals = (lineItems: OrderFeedingPlan, order: EnrichedOrder) => {
  emits('editFutureOrderMeals', lineItems, order)
}

const onAddFutureOrderProducts = (lineItems: OrderLineItem[], order: EnrichedOrder) => {
  emits('addFutureOrderProducts', lineItems, order)
}

const onEditFutureOrderProducts = (lineItems: OrderLineItem[], order: EnrichedOrder) => {
  emits('editFutureOrderProducts', lineItems, order)
}

const onEditFutureOrderDeliveryDate = (deliveryDetail: { date: string; slot: string }, order: EnrichedOrder) => {
  emits('editFutureOrderDeliveryDate', deliveryDetail.date, deliveryDetail.slot, order)
}
</script>

<template>
  <ViewLayout>
    <template #preView>
      <div class="md:tw-flex md:tw-justify-center">
        <img
          alt="order-calendar"
          src="@/assets/images/deliveries/order-calendar-bg-mobile.svg"
          class="tw-absolute tw-top-[75px] tw-z-10 tw-max-h-[100px] tw-scale-[3] tw-transform tw-transition-all tw-duration-300 md:tw-hidden"
          :class="calendarBgSvgTransition"
        />
        <img
          alt="order-bg"
          src="@/assets/images/deliveries/order-bg-desktop.svg"
          class="tw-absolute -tw-top-[115px] tw-z-10 tw-hidden tw-h-[540px] tw-object-cover md:tw-inline"
        />
        <div class="tw-relative tw-z-20 tw-mb-8 tw-w-full tw-space-y-4 tw-pt-12 tw-text-center">
          <PawHeading size="5xl" class=""><span class="tw-text-content-invert">Deliveries</span></PawHeading>

          <div class="tw-w-full">
            <CalendarTab
              :delivery-details="deliveryDetails"
              :default-index="activeIndex"
              @scroll-to-item="scrollToItem"
            />
          </div>
        </div>
      </div>
    </template>

    <TransitionGroup :name="slideFadeAnimationName">
      <template v-for="(order, orderIndex) in orderStore.orders">
        <DeliverySlide
          v-if="activeIndex === orderIndex"
          :data-testid="`test-future-delivery-slide-${orderIndex}`"
          :key="`future-order-${orderIndex}`"
          :order="orderStore.orders[orderIndex]"
          @edit-meals="onEditFutureOrderMeals($event, order)"
          @add-products="onAddFutureOrderProducts($event, order)"
          @edit-products="onEditFutureOrderProducts($event, order)"
          @edit-delivery-date="onEditFutureOrderDeliveryDate($event, order)"
        />
      </template>
    </TransitionGroup>
  </ViewLayout>
</template>

<style scoped>
.slide-fade-right-enter-from,
.slide-fade-left-leave-to {
  @apply tw-translate-x-full tw-transform md:tw-translate-x-0 md:tw-opacity-0;
}

.slide-fade-right-enter-active,
.slide-fade-right-leave-active,
.slide-fade-left-enter-active,
.slide-fade-left-leave-active {
  @apply -tw-z-20 tw-duration-300 tw-ease-in-out md:tw-duration-100;
}

.slide-fade-right-enter-to,
.slide-fade-right-leave-from,
.slide-fade-left-enter-to,
.slide-fade-left-leave-from {
  @apply tw-translate-x-0 tw-transform md:tw-opacity-100 md:tw-delay-300;
}

.slide-fade-right-leave-to,
.slide-fade-left-enter-from {
  @apply -tw-translate-x-full tw-transform md:tw-translate-x-0 md:tw-opacity-0;
}
</style>
