<script setup lang="ts">
import { RetrieveDogReturnDto } from '@repo/api-types/src/generated/entities'
import { PawHeading, PawButtonText } from '@lyka-pet-food/lyka-common'
import { computed } from 'vue'
import { useSubscriptionStore } from '@/stores/subscriptions'
import { shortenDogName } from '@/utils/dogName'

const props = defineProps<{
  dog: RetrieveDogReturnDto
  pets: Array<RetrieveDogReturnDto>
}>()

const subscriptionStore = useSubscriptionStore()

const feedingPlan = computed(() => subscriptionStore.getFeedingPlanByAnimalId(props.dog?.identifier))

const dogMealPlan = computed(() => (feedingPlan.value?.type === 'full_bowl' ? 'Full bowl plan' : 'Half bowl plan'))

const subscriptionFrequency = computed(() => subscriptionStore.userActiveSubscription?.cadenceInWeeks)

const planTitle = computed(() => shortenDogName(props.pets.map((x) => x.name ?? '')))

const mealPlanFrequency = computed(() => {
  if (!subscriptionFrequency.value) return ''
  return subscriptionFrequency.value > 1
    ? `${subscriptionFrequency.value} weeks`
    : `${subscriptionFrequency.value} week`
})

const emit = defineEmits<{
  (e: 'editMealPlan', dog: RetrieveDogReturnDto): void
}>()
</script>

<template>
  <div class="tw-mb-4 tw-flex tw-w-full tw-justify-between">
    <PawHeading size="2xl" type="subheading">{{ planTitle }}'s plan</PawHeading>
  </div>

  <div class="tw-flex tw-justify-between">
    <div class="tw-flex tw-gap-6">
      <div class="tw-size-[80px]">
        <img src="@/assets/images/dogs/starter-box.png" />
      </div>

      <div class="tw-space-y-1 tw-text-body-sm">
        <div class="tw-text-body-md">{{ dogMealPlan }}</div>
        <div>Every {{ mealPlanFrequency }}</div>
        <div>${{ feedingPlan?.dailyPrice }}/day</div>
      </div>
    </div>

    <div>
      <PawButtonText class="tw-text-surface-brand-accent" @click="emit('editMealPlan', dog)">Edit</PawButtonText>
    </div>
  </div>
</template>
