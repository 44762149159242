<script setup lang="ts">
import ViewLayout from '@/components/ViewLayout.vue'
import type { RetrieveDogReturnDto, UpdateAnimalDto } from '@repo/api-types/src/generated/entities'
import { PawHeading, PawTextField, PawDivider, PawButtonOutlined, PawButtonFilled } from '@lyka-pet-food/lyka-common'
import { onMounted, reactive, ref, watch } from 'vue'
import StickyBottomGeneral from '@/components/stickyNav/StickyBottomGeneral.vue'
import CardSelect from '@/components/CardSelect.vue'
import CardSelectGroup from '@/components/CardSelectGroup.vue'
import CallOut from '@/components/CallOut.vue'
import { useAnimalsStore } from '@/stores/animals'

const props = defineProps<{
  dog: RetrieveDogReturnDto
}>()

const emits = defineEmits<{
  (e: 'cancelEditProfile'): void
  (e: 'saveEditProfile', identifier: string, dog: UpdateAnimalDto): void
}>()

const animalsStore = useAnimalsStore()

const profileState = reactive({
  weight: props.dog.weight,
  bodyType: props.dog.bodyType,
  foodAllergies: props.dog.foodAllergies,
  healthIssues: props.dog.healthIssues,
  activityLevel: props.dog.activityLevel,
  eatingHabits: props.dog.eatingHabits,
  previousFoods: props.dog.previousFoods,
})

const hasHealthIssues = ref(props.dog.healthIssues && props.dog.healthIssues.length > 0 ? true : false)
const weightError = ref()

const onToggleNoHealthIssues = () => {
  if (!hasHealthIssues.value) {
    return
  }
  hasHealthIssues.value = false
  profileState.healthIssues = []
}

const onToggleNewHealthIssues = () => {
  hasHealthIssues.value = true
}

const onSelectFoodType = (option: { slug: string; name: string }) => {
  const existing = profileState.previousFoods?.find((slug) => slug === option.slug)
  if (existing) {
    profileState.previousFoods = profileState.previousFoods?.filter((slug) => slug !== option.slug)
    return
  }
  if (profileState.previousFoods) {
    profileState.previousFoods = [...profileState.previousFoods, option.slug]
    return
  }
  profileState.previousFoods = [option.slug]
}

const onSelectFoodAllergen = (option: { slug: string; name: string }) => {
  const existing = profileState.foodAllergies?.find((slug) => slug === option.slug)
  if (existing) {
    profileState.foodAllergies = profileState.foodAllergies?.filter((slug) => slug !== option.slug)
    return
  }
  if (profileState.foodAllergies) {
    profileState.foodAllergies = [...profileState.foodAllergies, option.slug]
    return
  }
  profileState.foodAllergies = [option.slug]
}

const onSelectHealthIssue = (option: { slug: string; name: string }) => {
  const existing = profileState.healthIssues?.find((slug) => slug === option.slug)
  if (existing) {
    profileState.healthIssues = profileState.healthIssues?.filter((slug) => slug !== option.slug)
    return
  }
  if (profileState.healthIssues) {
    profileState.healthIssues = [...profileState.healthIssues, option.slug]
    return
  }
  profileState.healthIssues = [option.slug]
}

const onConfirm = async () => {
  emits('saveEditProfile', props.dog.identifier, {
    ...props.dog,
    ...profileState,
  })
}

watch(profileState, (prev, next) => {
  if (next.weight) {
    weightError.value = ''
    const newWeight = parseFloat(next.weight)
    if (newWeight > 99) {
      profileState.weight = '99'
    } else if (newWeight < 1) {
      profileState.weight = '1'
    } else {
      profileState.weight = newWeight.toFixed(0)
    }
  } else {
    weightError.value = 'Weight must be at least 1 Kg'
  }
})
</script>

<template>
  <ViewLayout>
    <div class="tw-w-full tw-max-w-[1140px] tw-space-y-8 tw-pb-48">
      <div class="tw-flex tw-w-full tw-flex-row tw-items-start tw-self-start">
        <img
          src="@/assets/icons/chevron-left.svg"
          class="tw-cursor-pointer tw-pr-4 md:tw-pt-1"
          @click="emits('cancelEditProfile')"
        />
        <div class="tw-space-y-1">
          <PawHeading size="3xl">Review {{ props.dog.name }}'s profile</PawHeading>
        </div>
      </div>

      <div class="tw-w-full tw-space-y-8">
        <div class="tw-flex tw-flex-row">
          <img src="@/assets/icons/scale-unbalanced-flip.svg" class="tw-w-[30px] tw-h-[30px]" />
          <PawHeading size="xl" type="subheading" class="tw-pl-4 tw-font-semibold">Weight & Activity</PawHeading>
        </div>

        <div class="tw-text-2xl">{{ props.dog.name }}'s weight</div>

        <div class="tw-flex tw-flex-col tw-space-y-2">
          <PawTextField
            type="number"
            min="1"
            max="99"
            step="1"
            v-model="profileState.weight"
            class="lg:tw-w-80"
            suffix="Kg"
          />
          <div class="tw-text-content-danger">
            {{ weightError }}
          </div>
        </div>

        <PawDivider />

        <div class="tw-text-2xl">{{ props.dog.name }}'s body shape</div>
        <CallOut class="md:tw-w-[520px]">Morgan's weight increased, did their body shape change too?</CallOut>
        <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-space-x-4 tw-space-y-4 md:tw-space-y-0">
          <CardSelectGroup v-model="profileState.bodyType">
            <CardSelect value="slim" class="tw-basis-1/3">
              <img src="@/assets/icons/activity-level-lap-dog.svg" class="tw-w-24 tw-h-24" />
              <div class="tw-ml-8 tw-space-y-2">
                <div>A bit slim</div>
                <div class="tw-text-sm tw-opacity-75">Skinny waist, with visible ribs</div>
              </div>
            </CardSelect>
            <CardSelect value="perfect" class="tw-basis-1/3">
              <img src="@/assets/icons/activity-level-playful.svg" class="tw-w-24 tw-h-24" />
              <div class="tw-ml-8 tw-space-y-2">
                <div>Just right</div>
                <div class="tw-text-sm tw-opacity-75">Visible waist, easy to feel ribs</div>
              </div>
            </CardSelect>
            <CardSelect value="chunky" class="tw-basis-1/3">
              <img src="@/assets/icons/activity-level-ball-of-energy.svg" class="tw-w-24 tw-h-24" />
              <div class="tw-ml-8 tw-space-y-2">
                <div>Carries a bit extra</div>
                <div class="tw-text-sm tw-opacity-75">Hidden waist, hard to find ribs</div>
              </div>
            </CardSelect>
          </CardSelectGroup>
        </div>
        <PawDivider />

        <div class="tw-text-2xl">{{ props.dog.name }}'s activity level</div>
        <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-space-x-4 tw-space-y-4 md:tw-space-y-0">
          <CardSelectGroup v-model="profileState.activityLevel">
            <CardSelect value="chilled" class="tw-basis-1/3">
              <img src="@/assets/icons/activity-level-lap-dog.svg" class="tw-w-24 tw-h-24" />
              <div class="tw-ml-8 tw-space-y-2">
                <div>Lap dog</div>
                <div class="tw-text-sm">Mellow, loves sleep.</div>
                <div class="tw-text-sm tw-opacity-75"><30 minutes exercise</div>
              </div>
            </CardSelect>
            <CardSelect value="loves_to_play" class="tw-basis-1/3">
              <img src="@/assets/icons/activity-level-playful.svg" class="tw-w-24 tw-h-24" />
              <div class="tw-ml-8 tw-space-y-2">
                <div>Playful pupper</div>
                <div class="tw-text-sm">Energetic, small walks.</div>
                <div class="tw-text-sm tw-opacity-75">30-90 minutes exercise</div>
              </div>
            </CardSelect>
            <CardSelect value="ball_of_energy" class="tw-basis-1/3">
              <img src="@/assets/icons/activity-level-ball-of-energy.svg" class="tw-w-24 tw-h-24" />
              <div class="tw-ml-8 tw-space-y-2">
                <div>Ball of energy</div>
                <div class="tw-text-sm">Athletic, long walks & runs.</div>
                <div class="tw-text-sm tw-opacity-75">> 90 minutes exercise</div>
              </div>
            </CardSelect>
          </CardSelectGroup>
        </div>
        <PawDivider />

        <div class="tw-flex tw-flex-row">
          <img src="@/assets/icons/heart-pulse.svg" class="tw-w-[30px] tw-h-[30px]" />
          <PawHeading size="xl" type="subheading" class="tw-pl-4 tw-font-bold">Health</PawHeading>
        </div>

        <div class="tw-text-2xl">{{ props.dog.name }}'s food allergies and intolerances</div>
        <CallOut class="md:tw-w-[520px]"> Lyka is already free from gluten, soy, dairy, lactose and sugar. </CallOut>
        <div class="tw-space-x-2 tw-space-y-2">
          <template v-for="(option, idx) in animalsStore.foodAllergens">
            <PawButtonFilled
              @click="onSelectFoodAllergen(option)"
              variant="invert"
              class="tw-font-thin tw-bg-surface-inactive hover:tw-bg-surface-brand-accent hover:tw-text-white"
              :class="{
                '!tw-bg-surface-brand-accent !tw-text-white': profileState.foodAllergies?.find(
                  (slug) => slug === option.slug,
                ),
              }"
            >
              <div class="tw-flex tw-flex-row tw-space-x-2">
                <div>{{ option.name }}</div>
                <div
                  v-if="profileState.foodAllergies?.find((slug) => slug === option.slug)"
                  class="tw-mt-0.5 tw-rounded-2xl tw-p-1 tw-bg-surface-brand tw-bg-opacity-50"
                >
                  <img src="@/assets/icons/tick-white.svg" class="tw-w-3 tw-h-3" />
                </div>
              </div>
            </PawButtonFilled>
          </template>
        </div>

        <div class="tw-text-2xl">{{ props.dog.name }}'s health issues</div>
        <CallOut class="md:tw-w-[520px]"> For example, Skin itchiness, Urinary issues, dental issues etc. </CallOut>

        <div
          class="tw-space-y-4 md:tw-space-x-4 tw-flex tw-flex-col tw-items-center md:tw-flex-row md:tw-items-baseline"
        >
          <PawButtonOutlined
            @click="onToggleNoHealthIssues"
            class="tw-border-solid tw-w-[320px]"
            :class="{
              'selected-btn': !hasHealthIssues,
            }"
            >None</PawButtonOutlined
          >
          <PawButtonOutlined
            @click="onToggleNewHealthIssues"
            class="tw-border-solid tw-w-[320px]"
            :class="{
              'selected-btn': hasHealthIssues,
            }"
          >
            Has health issues
          </PawButtonOutlined>
        </div>

        <div v-if="hasHealthIssues" class="tw-space-x-2 tw-space-y-2">
          <template v-for="(option, idx) in animalsStore.healthIssues">
            <PawButtonFilled
              @click="onSelectHealthIssue(option)"
              variant="invert"
              class="tw-font-thin tw-bg-surface-inactive hover:tw-bg-surface-brand-accent hover:tw-text-white"
              :class="{
                '!tw-bg-surface-brand-accent !tw-text-white': profileState.healthIssues?.find(
                  (slug) => slug === option.slug,
                ),
              }"
            >
              <div class="tw-flex tw-flex-row tw-space-x-2">
                <div>{{ option.name }}</div>
                <div
                  v-if="profileState.healthIssues?.find((slug) => slug === option.slug)"
                  class="tw-mt-0.5 tw-rounded-2xl tw-p-1 tw-bg-surface-brand tw-bg-opacity-50"
                >
                  <img src="@/assets/icons/tick-white.svg" class="tw-w-3 tw-h-3" />
                </div>
              </div>
            </PawButtonFilled>
          </template>
        </div>
        <PawDivider />

        <div class="tw-flex tw-flex-row">
          <img src="@/assets/icons/bowl-food.svg" class="tw-w-[30px] tw-h-[30px]" />
          <PawHeading size="xl" type="subheading" class="tw-pl-4 tw-font-bold">Diet</PawHeading>
        </div>

        <div class="tw-text-2xl">{{ props.dog.name }}'s fussiness level</div>
        <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-space-x-4 tw-space-y-4 md:tw-space-y-0">
          <CardSelectGroup v-model="profileState.eatingHabits">
            <CardSelect value="very_fussy" class="tw-basis-1/3">
              <img src="@/assets/icons/fussiness-level-seriously-fussy.svg" class="tw-w-24 tw-h-24" />
              <div class="tw-ml-8 tw-space-y-2">
                <div>Seriously fussy</div>
                <div class="tw-text-sm tw-opacity-75">Only the finest wines and dines</div>
              </div>
            </CardSelect>
            <CardSelect value="choosy" class="tw-basis-1/3">
              <img src="@/assets/icons/fussiness-level-choosy.svg" class="tw-w-24 tw-h-24" />
              <div class="tw-ml-8 tw-space-y-2">
                <div>Can be choosy</div>
                <div class="tw-text-sm tw-opacity-75">Variety is the spice of life</div>
              </div>
            </CardSelect>
            <CardSelect value="eats_anything" class="tw-basis-1/3">
              <img src="@/assets/icons/fussiness-level-eat-anything.svg" class="tw-w-24 tw-h-24" />
              <div class="tw-ml-8 tw-space-y-2">
                <div>Will eat anything</div>
                <div class="tw-text-sm tw-opacity-75">The see-food diet</div>
              </div>
            </CardSelect>
          </CardSelectGroup>
        </div>
        <PawDivider />

        <div class="tw-text-2xl">{{ props.dog.name }}'s previous diet</div>
        <div class="tw-space-x-2 tw-space-y-2">
          <template v-for="(option, idx) in animalsStore.foodTypes">
            <PawButtonFilled
              @click="onSelectFoodType(option)"
              variant="invert"
              class="tw-font-thin tw-bg-surface-inactive hover:tw-bg-surface-brand-accent hover:tw-text-white"
              :class="{
                '!tw-bg-surface-brand-accent !tw-text-white': profileState.previousFoods?.find(
                  (slug) => slug === option.slug,
                ),
              }"
            >
              <div class="tw-flex tw-flex-row tw-space-x-2">
                <div>{{ option.name }}</div>
                <div
                  v-if="profileState.previousFoods?.find((slug) => slug === option.slug)"
                  class="tw-mt-0.5 tw-rounded-2xl tw-p-1 tw-bg-surface-brand tw-bg-opacity-50"
                >
                  <img src="@/assets/icons/tick-white.svg" class="tw-w-3 tw-h-3" />
                </div>
              </div>
            </PawButtonFilled>
          </template>
        </div>
      </div>
    </div>
    <template #postView>
      <StickyBottomGeneral @cancel="emits('cancelEditProfile')" @confirm="onConfirm" />
    </template>
  </ViewLayout>
</template>

<style scoped>
.selected-btn {
  @apply tw-bg-surface-brand-secondary hover:tw-cursor-default;
}
</style>
