<script setup lang="ts">
import { useAnimalsStore } from '@/stores/animals'
import { computed, ref } from 'vue'
import { RetrieveDogReturnDto } from '@repo/api-types/src/generated/entities'
import ViewLayout from '@/components/ViewLayout.vue'
import PetPhotoCarousel from '@/components/PetPhotoCarousel.vue'
import DogProfile from '@/components/DogProfile.vue'
import DogServingSize from '@/components/DogServingSize.vue'
import { PawDivider } from '@lyka-pet-food/lyka-common'
import DogMealPlan from '@/components/DogMealPlan.vue'
import { useMobile } from '@/composables/useMobile'
import DogBannerBgImage from '@/assets/images/banner-dogs-bg.webp'

const props = defineProps<{
  petIndex: number
}>()

const animalsStore = useAnimalsStore()
const isMobile = useMobile().isMobile

const currentIndex = ref(0)

const emit = defineEmits<{
  (e: 'editDetails', dog: RetrieveDogReturnDto, petIndex: number): void
  (e: 'editProfile', dog: RetrieveDogReturnDto): void
  (e: 'editMealPlan', dog: RetrieveDogReturnDto): void
  (e: 'editMeals', dog: RetrieveDogReturnDto): void
}>()

const pets = computed(() => animalsStore.dogs)
const currentDog = computed(() => pets.value[currentIndex.value])

const onScrollToIndex = (index: number) => {
  currentIndex.value = index
}

const onEditDetails = (dog: RetrieveDogReturnDto) => {
  emit('editDetails', dog, currentIndex.value)
}

const onEditProfile = (dog: RetrieveDogReturnDto) => {
  emit('editProfile', dog)
}

const onEditMealPlan = (dog: RetrieveDogReturnDto) => {
  emit('editMealPlan', dog)
}

const onEditMeals = (dog: RetrieveDogReturnDto) => {
  emit('editMeals', dog)
}
</script>

<template>
  <ViewLayout>
    <template #preView>
      <div
        class="tw-flex tw-justify-center tw-bg-cover tw-bg-[center_calc(50%-100px)] tw-bg-no-repeat"
        :style="{ backgroundImage: `url(${DogBannerBgImage})` }"
      >
        <div class="tw-relative tw-w-screen">
          <PetPhotoCarousel
            :pets="pets"
            :default-index="props.petIndex"
            @scroll-to-item="onScrollToIndex"
            @edit-pet="onEditDetails"
          />
        </div>
      </div>
    </template>

    <div class="tw-flex tw-w-full tw-max-w-[724px] tw-flex-col tw-space-y-7 tw-pb-32 -tw-mt-20">
      <div>
        <DogMealPlan :dog="currentDog" :pets="pets" @edit-meal-plan="onEditMealPlan" />
      </div>

      <PawDivider class="tw-my-4" />

      <div class="tw-space-y-4">
        <DogServingSize :dog="currentDog" @edit-meals="onEditMeals" />
      </div>

      <PawDivider class="tw-my-4" />

      <div class="tw-space-y-4">
        <DogProfile :dog="currentDog" @edit-profile="onEditProfile" />
      </div>
    </div>
  </ViewLayout>
</template>

<style scoped>
.slide-fade-right-enter-from,
.slide-fade-left-leave-to {
  @apply tw-translate-x-full tw-transform md:tw-translate-x-0 md:tw-opacity-0;
}

.slide-fade-right-enter-active,
.slide-fade-right-leave-active,
.slide-fade-left-enter-active,
.slide-fade-left-leave-active {
  @apply -tw-z-20 tw-duration-300 tw-ease-in-out md:tw-duration-100;
}

.slide-fade-right-enter-to,
.slide-fade-right-leave-from,
.slide-fade-left-enter-to,
.slide-fade-left-leave-from {
  @apply tw-translate-x-0 tw-transform md:tw-opacity-100 md:tw-delay-300;
}

.slide-fade-right-leave-to,
.slide-fade-left-enter-from {
  @apply -tw-translate-x-full tw-transform md:tw-translate-x-0 md:tw-opacity-0;
}
</style>
