import { ClientInferResponseBody } from '@ts-rest/core'
import { useClient } from '@/composables/useClient'
import { contract } from '@repo/api-contracts'
import { CourierDeliverySchedule } from '@repo/api-types'
import type { Order, BillingCycleOrder } from '@repo/api-types'

type UpdatedMeals = ClientInferResponseBody<
  typeof contract.ecommerce.postSubscriptionContractBillingCycleUpdateMeals,
  200
>

type AddProductsResponse = ClientInferResponseBody<typeof contract.ecommerce.updateSubscriptionContract, 200>

type UpdateProductsResponse = ClientInferResponseBody<
  typeof contract.ecommerce.updateSubscriptionContractBillingCycle,
  200
>

type UpdateDeliveryDate = ClientInferResponseBody<
  typeof contract.ecommerce.postSubscriptionContractBillingCycleUpdateDeliveryDate,
  200
>

export interface UpdateProductVariant {
  productVariantId: string
  quantity: number
}

export const useDeprecatedOrders = () => {
  const getUserOrders = async (): Promise<{
    error?: string | null
    past?: Order[]
    current?: Order
    future?: BillingCycleOrder[]
  }> => {
    const client = await useClient()
    const { status, body } = await client.ecommerce.getOrders()

    let error = null
    if (status == 500) {
      error = body.error
      return { error }
    } else if (status == 200) {
      return { past: body.past, current: body.current, future: body.future }
    }

    return { error: 'Unspecified server error' }
  }

  const updateMeals = async (
    subscriptionContractId: string,
    billingCycleId: string,
    meals: Array<UpdateProductVariant>,
  ): Promise<{ error: string | null; updates: UpdatedMeals | null }> => {
    const client = await useClient()
    const { status, body } = await client.ecommerce.postSubscriptionContractBillingCycleUpdateMeals({
      body: {
        lineItems: meals,
      },
      params: {
        id: subscriptionContractId,
        cycleIndex: billingCycleId,
      },
    })

    let error = null
    let updates: UpdatedMeals | null = null
    if (status === 200) {
      updates = body
    } else if (status === 404 || status === 500) {
      error = body.error
    }

    return { error, updates }
  }

  const addProducts = async (
    subscriptionContractId: string,
    products: Array<UpdateProductVariant>,
  ): Promise<{ error: string | null; updates: AddProductsResponse | null }> => {
    const client = await useClient()
    const { status, body } = await client.ecommerce.updateSubscriptionContract({
      body: {
        lines: products,
      },
      params: {
        id: subscriptionContractId,
      },
    })

    let error = null
    let updates: AddProductsResponse | null = null
    if (status === 200) {
      updates = body
    } else if (status === 404 || status === 500) {
      error = body.error
    }

    return { error, updates }
  }

  const updateProducts = async (
    subscriptionContractId: string,
    cycleIndex: number,
    products: Array<UpdateProductVariant>,
  ): Promise<{ error: string | null; updates: UpdateProductsResponse | null }> => {
    const client = await useClient()
    const { status, body } = await client.ecommerce.updateSubscriptionContractBillingCycle({
      body: {
        lines: products,
      },
      params: {
        id: subscriptionContractId,
        cycleIndex: cycleIndex,
      },
    })

    let error = null
    let updates: UpdateProductsResponse | null = null
    if (status === 200) {
      updates = body
    } else if (status === 404 || status === 500) {
      error = body.error
    }

    return { error, updates }
  }

  const getAvailableDeliveries = async (
    postCode: string,
    suburb: string,
  ): Promise<{
    error?: string | null
    deliverySchedule?: CourierDeliverySchedule[]
  }> => {
    const client = await useClient()
    const { status, body } = await client.courier.getDeliverySchedulesByPostCode({
      query: {
        postCode,
        suburb,
      },
    })

    let error = null

    if (status == 200) {
      return { deliverySchedule: body }
    } else if (status == 401) {
      error = body.error
      return { error }
    }

    return { error: 'Unspecified server error' }
  }

  const updateDelivery = async (
    deliveryDate: string,
    deliveryTimePreference: 'am' | 'pm',
    subscriptionContractId: string,
    billingCycleId: number,
  ): Promise<{ error: string | null; updates: UpdateDeliveryDate | null }> => {
    const client = await useClient()
    const { status, body } = await client.ecommerce.postSubscriptionContractBillingCycleUpdateDeliveryDate({
      body: {
        deliveryDate,
        deliveryTimePreference,
      },
      params: {
        id: subscriptionContractId,
        cycleIndex: billingCycleId,
      },
    })

    let error = null
    let updates: UpdateDeliveryDate | null = null
    if (status === 200) {
      updates = body
    } else if (status === 404 || status === 500) {
      error = body.error
    }

    return { error, updates }
  }

  return {
    getUserOrders,
    updateMeals,
    updateProducts,
    addProducts,
    getAvailableDeliveries,
    updateDelivery,
  }
}
