<script setup lang="ts">
import { DeliverySlot } from '@/types/order'
import { computed, ref } from 'vue'
import { PawHeading, PawDivider, PawButtonOutlined } from '@lyka-pet-food/lyka-common'
import RecipeCard from '@/components/RecipeCard.vue'
import { transformShippingAddress } from '@/utils/transforms/transformShippingAddress'
import { useAnimalsStore } from '@/stores/animals'
import { dateToLocalDateShort } from '@/utils/dateToLocalString'
import type { EnrichedOrder, OrderLineItem } from '@repo/api-types/src/order/types'
import { useProductStore } from '@/stores/products'
import { OrderDisplayType, OrderFeedingPlan } from '@repo/api-types/src/order/types'
import { useSubscriptionStore } from '@/stores/subscriptions'
import { orderContract } from '@repo/api-contracts'

const props = defineProps<{
  order: EnrichedOrder
}>()

const emits = defineEmits<{
  (e: 'editDeliveryDate', delivery: { date: string; slot: string }): void
  (e: 'delayDelivery'): void
  (e: 'editFeedback'): void
  (e: 'editMeals', lineItems: OrderFeedingPlan): void
  (e: 'addProducts', lineItems: OrderLineItem[]): void
  (e: 'editProducts', lineItems: OrderLineItem[]): void
  (e: 'editExtras'): void
  (e: 'showPaymentDetails'): void
}>()

const productStore = useProductStore()
const subscriptionStore = useSubscriptionStore()
const animalsStore = useAnimalsStore()

const extrasLineItems = computed(() => {
  return props.order.extras.map((x) => {
    const product = productStore.getProductByProductVariantId(x.productId as string)

    if (product) {
      return {
        imgPath: product.images.filter((x) => x.imageType === 'treat')[0]?.imageUrl,
        title: product.name,
        quantity: x.quantity,
      }
    } else {
      return {
        imgPath: '',
        title: '',
        quantity: 0,
      }
    }
  })
})

const mealLineItems = computed(() => {
  return props.order.feedingPlans
    .flatMap((plan) => plan.skus)
    .map((x) => {
      const product = productStore.getProductByProductVariantId(x.productId as string)

      if (product) {
        return {
          imgPath: product.images.filter((x) => x.imageType === 'bowl_icon')[0]?.imageUrl,
          title: product.name,
          quantity: x.quantity,
        }
      } else {
        return {
          imgPath: '',
          title: '',
          quantity: 0,
        }
      }
    })
})

const isMealEditable = computed(() => {
  return props.order.displayType !== OrderDisplayType.PAST && props.order.displayType !== OrderDisplayType.CURRENT
})

const isProductsEditable = computed(() => {
  return isMealEditable && extrasLineItems.value.length > 0
})

const isFeedbackAvailable = computed(() => {
  return props.order.displayType === OrderDisplayType.PAST
})

const isDeliveryEditable = computed(() => {
  return props.order.displayType !== OrderDisplayType.PAST && props.order.displayType !== OrderDisplayType.CURRENT
})

const paymentDateCutOff = computed(() => {
  return dateToLocalDateShort(props.order.billingDate).replace(/(.*) (.*) (.*)/, '$3, $1 $2')
})

const deliverySlot = computed(() => {
  if (props.order.expectedDeliverySlotId === DeliverySlot.AM) {
    return '0am-7am'
  } else {
    return '8am-6pm'
  }
})

const deliverySlotText = computed(() => {
  if (props.order.displayType === OrderDisplayType.PAST) {
    return 'Delivered ' + deliverySlot.value
  } else if (props.order.displayType === OrderDisplayType.CURRENT) {
    return 'Arriving ' + deliverySlot.value
  } else if (props.order.displayType === OrderDisplayType.FUTURE) {
    return 'You can now make changes to this!'
  }
})

const currentSubscription = computed(() => {
  return subscriptionStore.userActiveSubscription
})

const onUpdateDeliveryDate = () => {
  emits('editDeliveryDate', { date: props.order.deliveryDate, slot: props.order.expectedDeliverySlotId ?? '' })
}
</script>

<template>
  <div
    data-testid="test-delivery-slide"
    class="tw-absolute tw-w-full tw-space-y-8 tw-px-4 tw-pb-24 md:tw-w-[620px] md:tw-pb-64 tw-mt-8"
  >
    <div class="-tw-z-20 -tw-mx-4 -tw-mt-24 tw-flex tw-justify-between tw-bg-surface-brand-secondary tw-pt-12">
      <img src="@/assets/images/deliveries/banner-dog-1.svg" class="tw-pr-4" />
      <div class="tw-relative tw-z-10 tw-mb-4 tw-flex tw-flex-col tw-justify-center tw-space-y-3 tw-pr-3 tw-text-right">
        <div v-if="!isDeliveryEditable" class="tw-flex tw-justify-end">
          <img src="@/assets/images/deliveries/lock-closed-icon.svg" class="tw-pr-1" />Locked
        </div>
        <div class="tw-text-body-lg">{{ deliverySlotText }}</div>
        <div v-if="isFeedbackAvailable" class="tw-flex tw-cursor-pointer tw-items-center tw-text-content-accent">
          Feedback about delivery<img src="@/assets/images/deliveries/arrow-right-icon.svg" class="tw-pl-2" />
        </div>
      </div>
    </div>

    <div data-testid="test-delivery-info" class="tw-text-body-md tw-flex tw-w-full tw-text-start">
      <img src="@/assets/images/deliveries/pin-icon.svg" class="-tw-translate-y-0.5 tw-pr-1" />
      {{ transformShippingAddress(props.order.address) }}
    </div>

    <div v-if="isDeliveryEditable" class="tw-w-full tw-space-y-4">
      <PawDivider />
      <div class="tw-flex tw-gap-x-4">
        <PawButtonOutlined class="tw-border-solid" @click="onUpdateDeliveryDate">Edit delivery date</PawButtonOutlined>
        <PawButtonOutlined class="tw-border-solid">Delay 1 week</PawButtonOutlined>
      </div>
      <div class="tw-text-body-sm">
        Edit delivery before payment date on <span class="tw-font-bold">{{ paymentDateCutOff }}</span>
      </div>
      <PawDivider />
    </div>
    <PawDivider v-else />

    <div class="tw-w-full tw-space-y-4">
      <div class="tw-flex tw-items-center tw-justify-between">
        <PawHeading size="2xl" type="subheading"
          >{{ animalsStore.getDogById(order.feedingPlans[0].animalId)?.name }}'s meals</PawHeading
        >
        <div
          v-if="isMealEditable"
          class="tw-text-body-lg tw-cursor-pointer tw-text-content-accent"
          @click="emits('editMeals', order.feedingPlans[0])"
        >
          Edit
        </div>
      </div>
      <div :data-testid="`test-meal-pouches-${order.deliveryDate}`" class="tw-text-body-sm">
        <!-- {{ currentSubscription?.totalPouches }} x {{ currentSubscription?.pouchSizeInGrams }}g pouches • Serving size
        {{ currentSubscription?.dailyServingSizeInGrams }}g/day -->
      </div>
      <div class="tw-flex tw-flex-wrap tw-gap-y-4">
        <RecipeCard
          v-for="(lineItem, idx) in mealLineItems"
          :key="`bowl-${idx}`"
          :imagePath="lineItem.imgPath"
          :title="lineItem.title"
          :quantity="lineItem.quantity"
        />
      </div>
      <PawDivider />
    </div>

    <div class="tw-w-full tw-space-y-4">
      <div class="tw-flex tw-items-center tw-justify-between">
        <PawHeading size="2xl" type="subheading">Extras</PawHeading>
        <div
          v-if="isProductsEditable"
          class="tw-text-body-lg tw-cursor-pointer tw-text-content-accent"
          @click="emits('editProducts', order.extras)"
        >
          Edit
        </div>
      </div>
      <div class="tw-flex tw-flex-wrap">
        <RecipeCard
          v-for="(product, idx) in extrasLineItems"
          :key="`extra-${idx}`"
          :imagePath="product.imgPath"
          :title="product.title"
          :quantity="product.quantity"
          subscriptionStatus="plan"
        />
        <div
          v-if="isMealEditable"
          class="tw-flex tw-h-16 tw-w-16 tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-lg tw-bg-surface-brand-secondary"
          @click="emits('addProducts', order.extras)"
        >
          <img src="@/assets/images/deliveries/plus-icon.svg" />
        </div>
      </div>
    </div>

    <PawDivider />

    <div
      class="tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center tw-space-y-4 md:tw-flex-row md:tw-justify-between md:tw-space-y-0"
    >
      <div data-testid="test-payment" class="tw-text-body-lg">
        Total: <span class="tw-pr-1">${{ order.totalPrice }}</span>
        <!-- TODO: Get discounted price -->
        <!-- <span class="tw-font-bold">${{ order.discountedPrice }}</span> -->
      </div>

      <PawButtonOutlined variant="primary" class="tw-border-solid">See payment details</PawButtonOutlined>
    </div>
  </div>
</template>
